import { getClientIdForRequest, requests } from '../../../utils/requests'
import { MY_VIEW_PATH_SEGMENT } from '../../../constants/app'
import { clientsRequest } from '../../../utils/requestsTemplates'
import { Trans } from '../../../plugins/Translation'
import fixAPI from './fixAPI'
import getApiErrorMessage from '@/utils/getApiErrorMessage'

const getData = async (commit, clientId) => {
  try {
    const clientIdForRequest = getClientIdForRequest(clientId)

    const response = await requests.get(clientsRequest(clientIdForRequest))
    const data = fixAPI(response.data)
    commit('set', { id: clientId, info: data })
  } catch (err) {
    const errorKey = getApiErrorMessage(err)
    console.error(errorKey)
  }
}

const setProfileSettings = (commit, clientId, data) => {
  commit('setEmail', { id: clientId, payload: data.email })
  commit('setLocale', { id: clientId, payload: data.locale.toLowerCase() })
  commit('setFullName', { id: clientId, payload: data.name })
  commit('setNotifications', { id: clientId, payload: data.notifications })
  commit('setSettingsLoaded', { id: clientId, payload: true })
}

const getClientSettings = async (commit, clientId) => {
  commit('setLoadingProfileSetting', true)
  try {
    const response = await requests.get('/client-settings')
    setProfileSettings(commit, clientId, response.data)

    Trans.changeLanguage(response.data.locale.toLowerCase())
  } catch (err) {
    const errorKey = getApiErrorMessage(err)
    commit('setErrors', errorKey)
  }
  commit('setLoadingProfileSetting', false)
}

const processUpdateRequestsErrors = (commit, err) => {
  commit('setErrors', getApiErrorMessage(err))
}

export default {
  getDataByIdIfNeed: async (
    { dispatch, commit, state },
    clientId = MY_VIEW_PATH_SEGMENT
  ) => {
    // get data if are not already in store AND
    // if not trying to get client data for partner id
    if (!Object.prototype.hasOwnProperty.call(state.clients, clientId)) {
      await getData(commit, clientId)
    }
  },
  getDataById: async (
    { dispatch, commit, state },
    clientId = MY_VIEW_PATH_SEGMENT
  ) => {
    await getData(commit, clientId)
  },
  getAllClientsDataIfNeed: async ({ commit, state }) => {
    if (!state.clients.length) {
      let response = await requests.get(clientsRequest())
      response = response.data.map(client => {
        return fixAPI(client)
      })
      response.forEach(function (client) {
        commit('client/set', { id: client.id, info: client }, { root: true })
      })
    }
  },
  getClientSettingsIfNeed: async ({ commit, state }, clientId) => {
    if (!state.clients[clientId] || !state.clients[clientId].settingsLoaded) {
      await getClientSettings(commit, clientId)
    }
  },
  getClientSettings: async ({ commit }, clientId) => {
    await getClientSettings(commit, clientId)
  },
  setClientProfileSetting: async ({ commit }, { clientId, data }) => {
    commit('setErrors', {})
    commit('setLoadingProfileSettingSet', true)
    try {
      const requestData = { ...data }
      if (requestData.notifications) {
        requestData.notifications = Object.keys(requestData.notifications).reduce((acc, key) => {
          return requestData.notifications[key] ? [...acc, key] : acc
        }, [])
      }

      if (requestData.fullName) {
        requestData.name = requestData.fullName
        delete requestData.fullName
      }

      const updateClientSettingsAction = {
        name: 'client/getClientSettings',
        params: clientId
      }

      const response = await requests.patch('/client-settings', requestData, updateClientSettingsAction)
      setProfileSettings(commit, clientId, response.data)
      return response.data
    } catch (err) {
      processUpdateRequestsErrors(commit, err)
    } finally {
      commit('setLoadingProfileSettingSet', false)
    }
  },
  setClientLocaleSetting: async ({ commit }, { clientId, locale }) => {
    commit('setErrors', {})
    try {
      const updateClientSettingsAction = {
        name: 'client/getClientSettings',
        params: clientId
      }

      const response = await requests.patch('/client-settings', { locale }, updateClientSettingsAction)
      commit('setLocale', { id: clientId, payload: response.data.locale })
    } catch (err) {
      processUpdateRequestsErrors(commit, err)
    }
  }
}
