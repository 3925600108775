const ROUTE_OVERVIEW = 'ROUTE_OVERVIEW'
const ROUTE_PRODUCT_DETAILS = 'ROUTE_PRODUCT_DETAILS'
const ROUTE_INVOICES_AND_QUOTATIONS = 'ROUTE_INVOICES_AND_QUOTATIONS'
const ROUTE_PAYMENT_DETAILS = 'ROUTE_PAYMENT_DETAILS'
const ROUTE_BILLING_DETAILS = 'ROUTE_BILLING_DETAILS'
const ROUTE_TEAM_MEMBERS = 'ROUTE_TEAM_MEMBERS'
const ROUTE_SUPPORT_CENTER = 'ROUTE_SUPPORT_CENTER'
const ROUTE_LOGIN = 'ROUTE_LOGIN'
const ROUTE_PARTNER_VIEW = 'ROUTE_PARTNER_VIEW'
const ROUTE_PAYMENT_SUCCESS = 'ROUTE_PAYMENT_SUCCESS'
const ROUTE_PAYMENT_CANCELED = 'ROUTE_PAYMENT_CANCELED'
const ROUTE_TICKET_MODAL = 'ROUTE_TICKET_MODAL'
const ROUTE_RESET_PASSWORD = 'ROUTE_RESET_PASSWORD'

module.exports = {
  ROUTE_LOGIN,
  ROUTE_OVERVIEW,
  ROUTE_INVOICES_AND_QUOTATIONS,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_PAYMENT_CANCELED,
  ROUTE_PRODUCT_DETAILS,
  ROUTE_PAYMENT_DETAILS,
  ROUTE_BILLING_DETAILS,
  ROUTE_TEAM_MEMBERS,
  ROUTE_SUPPORT_CENTER,
  ROUTE_PARTNER_VIEW,
  ROUTE_TICKET_MODAL,
  ROUTE_RESET_PASSWORD
}
