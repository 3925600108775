import { reset, appendToProperty, set, removeFromProperty } from '../../../utils/vuex'
import getDefaultState from './defaultState'
const defaultState = getDefaultState()

export default {
  setRequests: set('requests'),
  appendRequest: appendToProperty('requests'),
  removeRequest: removeFromProperty('requests'),
  setServiceUnavailableError: set('serviceUnavailableError'),
  reset: reset(defaultState),
  setShowPendingRequestWarning: set('showPendingRequestWarning')
}
