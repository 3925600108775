<template>
  <div>
    <material-billing-details-card :client-id="clientId" />
    <material-billing-contact-details-card />
  </div>
</template>

<script>
export default {
  computed: {
    clientId () {
      return this.$route.params.clientId
    }
  }
}
</script>
