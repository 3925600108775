import Vue from 'vue'
import { set, reset } from '../../../utils/vuex'
import getDefaultState from './defaultState'
const defaultState = getDefaultState()

export default {
  setDrawer: set('drawer'),
  setProductsDrawer: set('productsDrawer'),
  reset: reset(defaultState),
  setLoading: (state, payload) => {
    Vue.set(state.loading, payload.to, payload.value)
  }
}
