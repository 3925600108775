import axios from 'axios'
import router from '../../../router'
import { MY_VIEW_PATH_SEGMENT } from '../../../constants/app'
import { ROUTE_LOGIN, ROUTE_OVERVIEW } from '../../../constants/routeNames'
import { DEFAULT_LANGUAGE } from '../../../constants/localization'
import services from './services'
import getApiErrorMessage from '@/utils/getApiErrorMessage'

const resettableModules = [
  'client',
  'contract',
  'invoicesAndQuotations',
  'layout',
  'member',
  'order',
  'pendingRequests',
  'productDetails',
  'recurringPayments',
  'support'
]

const redirectToOverview = (rootState) => {
  const clientLocale = rootState.client.clients[MY_VIEW_PATH_SEGMENT].locale
  const overviewPath = `/${clientLocale}/${MY_VIEW_PATH_SEGMENT}/overview`
  router.push({
    path: overviewPath,
    params: {
      locale: clientLocale,
      clientId: MY_VIEW_PATH_SEGMENT
    },
    name: ROUTE_OVERVIEW
  })
}

export default {
  submit: async ({ rootState, commit, dispatch }, payload) => {
    commit('setLoading', true)
    try {
      const { data } = await services.tryToLoginByEmailAndPassword(payload.email, payload.password, commit)
      services.applyUserData(data, commit)
      await dispatch('client/getClientSettingsIfNeed', MY_VIEW_PATH_SEGMENT, { root: true })
      redirectToOverview(rootState)
    } catch (err) {
      const errorKey = getApiErrorMessage(err)
      commit('setError', errorKey)
    }
    commit('setLoading', false)
  },
  resetPassword: ({ commit }) => {
    commit('setResetPasswordSent', false)
  },
  sendResetPassword: async ({ commit, dispatch }, payload) => {
    commit('setLoading', true)
    commit('setError', null)
    try {
      await services.resetPassword(payload.email)
      commit('setResetPasswordSent', true)
    } catch (err) {
      const errorKey = getApiErrorMessage(err)
      commit('setError', errorKey)
    }
    commit('setLoading', false)
  },
  loginByToken: async ({ rootState, commit, dispatch }, token) => {
    const response = await services.tryToLoginByToken(token)
    services.applyUserData(response.data, commit)
  },
  logout: async ({ commit }) => {
    for (const moduleName of resettableModules) {
      commit(`${moduleName}/reset`, {}, { root: true })
    }
    services.resetUserData(commit)

    const persistentAuthTokens = axios.defaults.headers.common.Authorization &&
      axios.defaults.headers.common.Authorization
        .split(',')
        .filter(t => !t.includes('Bearer'))
        .join(',')

    if (persistentAuthTokens) {
      axios.defaults.headers.common.Authorization = persistentAuthTokens
    } else {
      delete axios.defaults.headers.common.Authorization
    }

    const routerLang = router.currentRoute.params.locale || DEFAULT_LANGUAGE
    router.push({
      name: ROUTE_LOGIN,
      path: `/${routerLang}/login`,
      params: {
        locale: routerLang
      }
    })
  }
}
