const clientsRequest = (clientId = '') => `/clients${clientId ? `/${clientId}` : ''}`
const contractsRequest = clientId => `/clients/${clientId}/contracts`
const invoicesRequest = (clientId, pageLimit, page) =>
    `/clients/${clientId}/invoices?per_page=${pageLimit}&page=${page}`
const invoicesAndQuotationsPageRequest = (clientId, pageLimit) =>
    `/clients/${clientId}/invoicesAndQuotations?invoicesPerPage=${pageLimit}`
const downloadQuotationRequest = (clientId, quotationId) =>
    `/clients/${clientId}/quotations/${quotationId}/download`
const downloadInvoiceRequest = (clientId, invoiceId) =>
    `/clients/${clientId}/invoices/${invoiceId}/download`
const createInvoiceRequest = (clientId, quotationId) => `/clients/${clientId}/quotations/${quotationId}/create_invoice`

const authenticationByLoginRequest = '/authentications/by_login'
const authenticationByTokenRequest = '/authentications/by_token'
const authenticationResetPasswordRequest = '/authentications/reset-password'

const membersRequest = (clientId, memberId = '') =>
    `/clients/${clientId}/members${memberId ? `/${memberId}` : ''}`

const productChangePlanGetPackagesPricesRequest = (
  clientId,
  contractId
) =>
    `/clients/${clientId}/contracts/${contractId}/product/plugins`
const productChangePlanCreateQuotationRequest = (
  clientId,
  contractId
) =>
    `/clients/${clientId}/contracts/${contractId}/product/submit`
const productChangePlanGetOrderSummaryRequest = (
  clientId,
  contractId
) =>
    `/clients/${clientId}/contracts/${contractId}/product/summary`

const downloadContractFileRequest = (clientId, contractId, documentName) =>
    `/clients/${clientId}/contracts/${contractId}/download/${documentName}`
const downloadContractPackageRequest = (clientId, contractId, packageType) =>
    `/clients/${clientId}/contracts/${contractId}/download_package?packageDownloadType=${packageType}`

const ticketsRequest = (clientId, ticketId = '') =>
    `/clients/${clientId}/tickets${ticketId ? `/${ticketId}` : ''}`
const requestsRequest = clientId => `/clients/${clientId}/requests`
const supportLinksRequest = clientId => `/clients/${clientId}/support/links`
const supportManualsRequest = clientId =>
    `/clients/${clientId}/support/owners_manual`

const pendingRequestsRequest = (clientId, requestId) =>
    `/clients/${clientId}/pending_requests/${requestId}`

const dealRegistrationRequest = '/clients/deal-registration'

module.exports = {
  pendingRequestsRequest,
  clientsRequest,
  contractsRequest,
  invoicesRequest,
  invoicesAndQuotationsPageRequest,
  downloadQuotationRequest,
  downloadInvoiceRequest,
  authenticationByLoginRequest,
  authenticationByTokenRequest,
  membersRequest,
  productChangePlanGetPackagesPricesRequest,
  downloadContractFileRequest,
  downloadContractPackageRequest,
  ticketsRequest,
  requestsRequest,
  productChangePlanCreateQuotationRequest,
  productChangePlanGetOrderSummaryRequest,
  supportLinksRequest,
  supportManualsRequest,
  authenticationResetPasswordRequest,
  dealRegistrationRequest,
  createInvoiceRequest
}
