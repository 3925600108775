<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        lg="6"
        xl="5"
      >
        <material-invoices-and-quotations-quotations-card
          :client-id="clientId"
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
        xl="7"
      >
        <material-invoices-and-quotations-invoices-card
          :client-id="clientId"
        />
      </v-col>
      <v-col data-cy="bank-account-card">
        <material-invoices-and-quotations-bank-contact-card />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      interval: null
    }
  },
  computed: {
    ...mapGetters('invoicesAndQuotations', {
      quotationsPendingInvoiceGetter: 'quotationsPendingInvoice'
    }),
    clientId () {
      return this.$route.params.clientId
    },
    quotationsPendingInvoice () {
      return this.quotationsPendingInvoiceGetter(this.clientId)
        ? this.quotationsPendingInvoiceGetter(this.clientId)
        : []
    }
  },
  watch: {
    quotationsPendingInvoice: function (value) {
      if (value.length === 0 && this.interval) {
        clearInterval(this.interval)
      } else if (value.length > 0) {
        this.refreshInvoicesAndQuotations()
      }
    }
  },
  created () {
    if (this.quotationsPendingInvoice.length > 0) {
      this.refreshInvoicesAndQuotations()
    }
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    ...mapActions('invoicesAndQuotations', [
      'getInvoicesAndQuotations'
    ]),
    refreshInvoicesAndQuotations () {
      if (this.interval === null) {
        this.interval = setInterval(async () => {
          await this.getInvoicesAndQuotations(this.clientId)
        }, 10 * 1000)
      }
    }
  }
}
</script>
