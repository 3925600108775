export default {
  client: state => clientId => {
    return Object.prototype.hasOwnProperty.call(state.clients, clientId) ? state.clients[clientId] : []
  },
  payments: state => clientId => {
    return Object.prototype.hasOwnProperty.call(state.clients, clientId) ? state.clients[clientId] : []
  },
  nextPaymentGetter: state => clientId => {
    if (Object.prototype.hasOwnProperty.call(state.clients, clientId) && state.clients[clientId].length) {
      const payments = state.clients[clientId]
      payments.sort(function (a, b) {
        return Date.parse(a.nextPayment) - Date.parse(b.nextPayment)
      })
      return payments[0]
    }
    return null
  },
  paymentsCountGetter: state => clientId => {
    return Object.prototype.hasOwnProperty.call(state.clients, clientId) ? state.clients[clientId].length : 0
  }
}
