import { TYPE_PRODUCT } from '../../../constants/app'

export default {
  productContractsCount: (state, getters) => clientId => {
    return getters.productContracts(clientId).length
  },
  contract: state => ({ clientId, contractId }) => {
    return (
      !(clientId && contractId) ||
      state.clients[clientId].find(contract => contract.id === contractId)
    )
  },
  client: state => clientId => {
    return !clientId || state.clients[clientId]
  },
  productContracts: state => clientId => {
    return state.clients[clientId]
      ? state.clients[clientId].filter(
        c => c.type === TYPE_PRODUCT
      )
      : []
  }
}
