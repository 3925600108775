import { reset, setClientVariableField, setClientInfo, set } from '../../../utils/vuex'
import getDefaultState from './defaultState'
const defaultState = getDefaultState()

export default {
  set: setClientInfo(),
  setLoadingAll: set('loadingAll'),
  setLoadingInvoices: set('loadingInvoices'),
  setValue: setClientVariableField(),
  setAllInvoicesDownloadingFlag: set('allInvoicesDownloading'),
  setAllQuotationsDownloadingFlag: set('allQuotationsDownloading'),
  reset: reset(defaultState)
}
