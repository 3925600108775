import { result } from 'lodash'
import getApiErrorMessage from './getApiErrorMessage'
import i18n from '@/plugins/i18n'
import { PENDING_REQUESTS_CODE } from '@/constants/pendingRequests'

const getFormErrorMessages = (e) => {
  if (result(e, 'response.status') !== PENDING_REQUESTS_CODE) {
    const errorData = result(e, 'response.data')
    if (!errorData) {
      return i18n.t('unknownErrorHasOccurredPleaseTryAgainLater')
    }
    if (errorData.message || errorData.error || errorData.messages || errorData.errors) {
      return getApiErrorMessage(e)
    }

    Object.keys(errorData).forEach(key => {
      errorData[key] = Array.isArray(errorData[key])
        ? errorData[key].join(', ')
        : errorData[key]
    })
    return errorData
  }
  return {}
}

export default getFormErrorMessages
