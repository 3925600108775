import { reset, set, setClientInfo, setFiledInClientArrayItem, setVariableField } from '../../../utils/vuex'
import getDefaultState from './defaultState'
const defaultState = getDefaultState()

export default {
  set: setClientInfo(),
  setPaymentEnabled: setFiledInClientArrayItem('enabled'),
  setError: set('error'),
  setLoading: set('loading'),
  setEditingLoading: setVariableField('editingLoading'),
  reset: reset(defaultState)
}
