import Vue from 'vue'
import axios from 'axios'
import VueGtm from '@gtm-support/vue2-gtm'

import './components'

import { sync } from 'vuex-router-sync'
import moment from 'moment'
import lodash from 'lodash'
import App from './App'
import router from './router'
import store from './store'

import i18n from './plugins/i18n'
import { Trans } from './plugins/Translation'

import './styles/main.scss'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'

import { JWT_NAME, MONTH_PERIOD, YEAR_PERIOD, DAY_PERIOD, PENDING_REQUESTS_NAME } from './constants/app'

sync(store, router)

const jwtToken = localStorage.getItem(JWT_NAME) ? `Bearer ${localStorage.getItem(JWT_NAME)}` : null

if (jwtToken) {
  axios.defaults.headers.common.Authorization = jwtToken
}

if (process.env.VUE_APP_API_TOKEN) {
  axios.defaults.headers.common.token = process.env.VUE_APP_API_TOKEN
}

axios.defaults.baseURL = process.env.VUE_APP_API_PATH

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.config.productionTip = false

Vue.filter('formatDate', function (ISODate) {
  return moment(ISODate).format('DD.MM.YYYY')
})
Vue.filter('formatDateTime', function (ISODate) {
  return moment(ISODate).format('DD.MM.YYYY LT')
})
Vue.filter('formatDateTimeWithTimezone', function (ISODate) {
  return moment.parseZone(ISODate).toDate()
})
Vue.filter('camelize', function (string) {
  return lodash.camelCase(string)
})
Vue.filter('recurringPeriodToString', function (payment) {
  if (payment.enabled) {
    if (payment.recurringPeriod.cycle === MONTH_PERIOD) {
      return i18n.tc('payment.nMonths', payment.recurringPeriod.period)
    } else if (payment.recurringPeriod.cycle === YEAR_PERIOD) {
      return i18n.tc('payment.nYears', payment.recurringPeriod.period)
    } else if (payment.recurringPeriod.cycle === DAY_PERIOD) {
      return i18n.tc('payment.nDays', payment.recurringPeriod.period)
    }
    return 'unknown cycle'
  }
  return '-'
})

window.onbeforeunload = function (e) {
  const pendingRequests = store.state.pendingRequests.requests
  localStorage.setItem(PENDING_REQUESTS_NAME, JSON.stringify(pendingRequests))

  if (store.state.productDetails.downloadingPackage) {
    e.returnValue = true
  }
}

if (process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    defer: true,
    compatibility: true,
    enabled: true,
    debug: true,
    vueRouter: router
  })
}

/* eslint-disable no-new */
new Vue({
  router,
  store,
  vuetify,
  i18n,
  mounted () {
    try {
      const pendingRequests = JSON.parse(
        localStorage.getItem(PENDING_REQUESTS_NAME)
      )
      store.commit('pendingRequests/setRequests', pendingRequests, {
        root: true
      })
    } catch (e) {
      localStorage.removeItem(PENDING_REQUESTS_NAME)
    }
  },
  render: h => h(App)
}).$mount('#app')
