const DOCUMENTATION = {
  knowledgeBase: {
    easyRedmine: process.env.VUE_APP_SUPPORT_KNOWLEDGE_BASE_ER,
    easyProject: process.env.VUE_APP_SUPPORT_KNOWLEDGE_BASE_EP,
    easyProjectCZ: process.env.VUE_APP_SUPPORT_KNOWLEDGE_BASE_EP_CZ
  },
  faq: {
    easyRedmine: process.env.VUE_APP_SUPPORT_FAQ_ER,
    easyProject: process.env.VUE_APP_SUPPORT_FAQ_EP,
    easyProjectCZ: process.env.VUE_APP_SUPPORT_FAQ_EP_CZ
  },
  manual: {
    easyRedmine: process.env.VUE_APP_SUPPORT_MANUAL_ER,
    easyProject: process.env.VUE_APP_SUPPORT_MANUAL_EP,
    easyProjectCZ: process.env.VUE_APP_SUPPORT_MANUAL_EP_CZ
  }
}

const CLOSED_TICKET_HEADERS = [
  {
    text: 'support.state',
    value: 'state'
  },
  {
    text: 'support.subject',
    value: 'subject'
  },
  {
    text: 'id',
    value: 'id'
  },
  {
    text: 'support.lastUpdate',
    value: 'lastUpdate'
  },
  {
    text: 'actions',
    value: 'actions',
    align: 'center',
    sortable: false
  }
]

const OPEN_TICKET_HEADERS = [
  {
    text: 'support.state',
    value: 'state'
  },
  {
    text: 'support.subject',
    value: 'subject'
  },
  {
    text: 'id',
    value: 'id'
  },
  {
    text: 'support.priority',
    value: 'priority'
  },
  {
    text: 'support.lastUpdate',
    value: 'lastUpdate'
  },
  {
    text: 'actions',
    value: 'actions',
    align: 'center',
    sortable: false
  }
]

const PRIORITY_NORMAL = 'Normal'
const PRIORITY_LOW = 'Low'
const PRIORITY_MEDIUM = 'Medium'
const PRIORITY_HIGH = 'High'
const PRIORITY_URGENT = 'Urgent'

const ALL_PRIORITIES = [
  PRIORITY_LOW,
  PRIORITY_NORMAL,
  PRIORITY_MEDIUM,
  PRIORITY_HIGH,
  PRIORITY_URGENT
]
const ALL_PRIORITIES_WITH_VALUES = {}
ALL_PRIORITIES_WITH_VALUES[PRIORITY_LOW] = 0
ALL_PRIORITIES_WITH_VALUES[PRIORITY_NORMAL] = 1
ALL_PRIORITIES_WITH_VALUES[PRIORITY_MEDIUM] = 2
ALL_PRIORITIES_WITH_VALUES[PRIORITY_HIGH] = 3
ALL_PRIORITIES_WITH_VALUES[PRIORITY_URGENT] = 4

module.exports = {
  DOCUMENTATION,
  CLOSED_TICKET_HEADERS,
  OPEN_TICKET_HEADERS,
  PRIORITY_NORMAL,
  PRIORITY_LOW,
  PRIORITY_HIGH,
  PRIORITY_URGENT,
  PRIORITY_MEDIUM,
  ALL_PRIORITIES,
  ALL_PRIORITIES_WITH_VALUES
}
