import Vue from 'vue'

export const setField = (property, field) => (state, payload) => {
  state[property][field] = payload
}

export const setVariableField = property => (state, data) => {
  Vue.set(state[property], data.field, data.value)
}

export const setFieldInArray = (arrayName, id, field) => (state, data) => {
  const index = state[arrayName].findIndex(x => x.id === id)
  if (index >= 0) state[arrayName][index][field] = data
}

export const appendToField = (property, field) => (state, payload) => {
  if (typeof payload === 'object') {
    state[property][field] = [...state[property][field], ...payload]
  } else {
    state[property][field] = [...state[property][field], payload]
  }
}

export const appendToProperty = property => (state, payload) => {
  if (state[property]) {
    state[property] = [...state[property], payload]
  } else {
    state[property] = [payload]
  }
}

export const removeFromProperty = (property, idKey = 'id') => (
  state,
  idToRemove
) => {
  state[property] = state[property].filter(item => item[idKey] !== idToRemove)
}

export const removeFromArrayField = (property, field) => (state, index) => {
  state[property][field].splice(index, 1)
}

export const set = property => (state, payload) => (state[property] = payload)

export const setErrors = () => (state, payload) => {
  state.errors = payload
  if (Array.isArray(payload) || typeof payload === 'string') {
    state.showApiErrors = !!payload
  } else {
    state.showApiErrors = false
  }
}

export const setValue = (property, value) => state => (state[property] = value)

export const toggle = property => state => (state[property] = !state[property])

export const replaceRecordInList = (key, idKey = 'id') => (state, data) => {
  const id = data[idKey]
  const index = state[key].findIndex(x => x[idKey] === id)
  return index < 0 ? state[key].push(data) : state[key].splice(index, 1, data)
}

export const replaceItemInClientArray = (field, idKey = 'id') => (
  state,
  data
) => {
  state.clients[data.clientId][field] = state.clients[data.clientId][field].map((item, i) => {
    if (item[idKey] === data[idKey]) {
      return data.payload
    }
    return item
  })
}

export const removeFromClientArray = (field, idKey = 'id') => (state, data) => {
  state.clients[data.clientId][field] = state.clients[data.clientId][
    field
  ].filter(item => item[idKey] !== data[idKey])
}

export const appendToClientArray = field => (state, data) => {
  state.clients[data.clientId][field] = [
    ...state.clients[data.clientId][field],
    data.info
  ]
}

export const pushToClientArray = field => (state, data) => {
  state.clients[data.clientId][field] = [
    data.info,
    ...state.clients[data.clientId][field]
  ]
}

export const setClientInfo = () => (state, data) => {
  Vue.set(state.clients, data.id, data.info)
}

export const setClientFieldProperty = (field) => (state, data) => {
  if (Object.prototype.hasOwnProperty.call(state.clients, data.clientId)) {
    if (state.clients[data.clientId][field]) {
      Vue.set(state.clients[data.clientId][field], data.property, data.payload)
    } else {
      Vue.set(state.clients[data.clientId], field, {})
      Vue.set(state.clients[data.clientId][field], data.property, data.payload)
    }
  }
}

export const appendToClientFieldProperty = (field) => (state, data) => {
  const clientFieldProperty = state.clients[data.clientId][field][data.property]
  if (clientFieldProperty) {
    Vue.set(state.clients[data.clientId][field], data.property, [...clientFieldProperty, data.payload])
  } else {
    Vue.set(state.clients[data.clientId][field], data.property, [data.payload])
  }
}

export const setClientField = field => (state, data) => {
  const newClient = { ...state.clients[data.id] }
  newClient[field] = data.payload
  Vue.set(state.clients, data.id, newClient)
}

export const setClientVariableField = () => (state, data) => {
  const newClient = { ...state.clients[data.id] }
  newClient[data.field] = data.payload
  Vue.set(state.clients, data.id, newClient)
}

export const setFiledInClientArrayItem = (field, idKey = 'id') => (state, data) => {
  state.clients[data.clientId] = state.clients[data.clientId].map(item => {
    if (item[idKey] === data[idKey]) {
      item[field] = data.payload
    }
    return item
  })
}

export const reset = defaultState => state => {
  for (const key of Object.keys(defaultState)) {
    state[key] = defaultState[key]
  }
}
