<template>
  <div>
    <v-alert
      v-if="error"
      v-t="'errors.somethingWentWrongPleaseTryAgainLater'"
      color="error"
      type="error"
    />
    <material-recurring-payments-table />
    <material-recurring-payments-cancel-loading-modal-window v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('recurringPayments', ['loading', 'error'])
  },
  mounted () {
    this.setError(false)
  },
  methods: {
    ...mapMutations('recurringPayments', ['setError'])
  }
}
</script>
