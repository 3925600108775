import axios from 'axios'
import moment from 'moment'
import { MY_VIEW_PATH_SEGMENT, CLIENT_ID_NAME } from '../constants/app'
import { EXPIRATION_TIMEOUT_IN_MINUTES, PENDING_REQUESTS_CODE } from '../constants/pendingRequests'
import store from '../store'
const JWT_EXPIRATION_CODE = 401

const requestsMiddleware = (request, errors = false) => async (url, body, updateDataAction = null) => {
  try {
    const response = await request(url, body)
    return response
  } catch (e) {
    handleRequestsErrors(e, updateDataAction)
    throw e
  }
}

export const requests = {
  delete: requestsMiddleware((url, body = null) => axios.delete(url, body)),
  get: requestsMiddleware((url, params) => axios.get(url, { params })),
  put: requestsMiddleware((url, body) => axios.put(url, body), true),
  patch: requestsMiddleware((url, body) => axios.patch(url, body), true),
  post: requestsMiddleware((url, body) => axios.post(url, body), true)
}

export const handleRequestsErrors = (error, updateDataAction) => {
  if (error && error.response) {
    switch (error.response.status) {
      case JWT_EXPIRATION_CODE:
        store.dispatch('login/logout', {}, { root: true })
        break
      case PENDING_REQUESTS_CODE:
        if (updateDataAction) {
          const now = moment(new Date())
          const expiration = now.add(EXPIRATION_TIMEOUT_IN_MINUTES, 'minutes').toDate()
          const requestId = error.response.data.message.split('Remote server is down, request_id: ')[1]
          const newRequest = {
            id: requestId,
            updateDataAction,
            expiration: expiration
          }
          store.commit('pendingRequests/appendRequest', newRequest)
        } else {
          store.commit('pendingRequests/setServiceUnavailableError', true)
        }
        store.commit('pendingRequests/setShowPendingRequestWarning', true)
        break
      default:
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

const getMergedBearerToken = (newBearerToken) => {
  const activeAuthHeaders = axios.defaults.headers.common.Authorization || ''
  const bearerToken = `Bearer ${newBearerToken}`
  if (activeAuthHeaders) {
    const otherTokens = activeAuthHeaders.split(',').filter(t => (
      !t.includes('Bearer')
    ))

    return [...otherTokens, bearerToken].join(',')
  }

  return bearerToken
}

export const setRequestsAuthHeader = token => {
  axios.defaults.headers.common.Authorization = getMergedBearerToken(token)
}

export const getClientIdForRequest = routeId => {
  if (routeId === MY_VIEW_PATH_SEGMENT) {
    const clientId = localStorage.getItem(CLIENT_ID_NAME)
    return clientId !== null && clientId !== 'null' ? clientId : null
  }
  return routeId
}
