<template>
  <div>
    <template v-if="!productContractsCount">
      <p>{{ $t("productDetails.noProductContactsYet") }}</p>
    </template>
    <template v-else>
      <material-product-details-card
        v-if="client"
        :client="client"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('client', ['clientById']),
    ...mapGetters('contract', { productContractsCountGetter: 'productContractsCount' }),
    client () {
      return this.clientById(this.$route.params.clientId)
    },
    productContractsCount () {
      const routeParams = this.$route.params
      return this.productContractsCountGetter(routeParams.clientId)
    }
  }
}
</script>
