import JsZip from 'jszip'
import { requests, getClientIdForRequest } from '../../../utils/requests'
import { INVOICES_PER_PAGE_DEFAULT_COUNT } from '../../../constants/app'
import { downloadFile, uploadFileToBrowser } from '../../../utils/download'
import {
  invoicesRequest,
  invoicesAndQuotationsPageRequest,
  downloadInvoiceRequest,
  createInvoiceRequest
} from '../../../utils/requestsTemplates'
import fixAPI from './fixAPI'
const today = new Date().toISOString().slice(0, 10)

export default {
  getOverviewData: async ({ commit, state }, clientId) => {
    if (!state.clients[clientId]) {
      commit('setLoadingInvoices', true)

      const invoices = await getPageOfInvoices(
        clientId,
        1,
        INVOICES_PER_PAGE_DEFAULT_COUNT
      )

      commit('set', { id: clientId, info: { invoices: invoices } })
      commit('setLoadingInvoices', false)
    }
  },
  getInvoicesAndQuotationsIfNeed: async ({ commit, state }, clientId) => {
    const clientData = state.clients[clientId]
    if (!clientData || !clientData.quotations) {
      await getInvoicesAndQuotations(clientId, commit)
    }
  },
  getInvoicesAndQuotations: async ({ commit, state }, clientId) => {
    await getInvoicesAndQuotations(clientId, commit)
  },
  getInvoices: async (
    { commit, state },
    { page, invoicesPerPage, clientId }
  ) => {
    if (state.clients[clientId]) {
      commit('setLoadingInvoices', true)

      const invoices = await getPageOfInvoices(clientId, page, invoicesPerPage)

      commit('setValue', {
        id: clientId,
        field: 'invoices',
        payload: invoices
      })
      commit('setLoadingInvoices', false)
    }
  },
  downloadInvoicePDF: async (
    _, { clientId, invoiceId }
  ) => {
    try {
      const requestClientId = getClientIdForRequest(clientId)
      const data = await downloadFile(
        downloadInvoiceRequest(requestClientId, invoiceId),
        {
          name: 'invoicesAndQuotations/downloadInvoicePDF',
          params: { clientId, invoiceId }
        }
      )
      uploadFileToBrowser(data, `invoice_${invoiceId}_${today}`, 'pdf')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
  downloadAllInvoices: async ({ commit, state }, clientId) => {
    if (
      !state.allInvoicesDownloading &&
      Object.prototype.hasOwnProperty.call(state.clients, clientId) &&
      Array.isArray(state.clients[clientId].allInvoicesIds) &&
      state.clients[clientId].allInvoicesIds.length > 0
    ) {
      commit('setAllInvoicesDownloadingFlag', true)

      try {
        const clientIdForRequest = getClientIdForRequest(clientId)
        const allIds = state.clients[clientId].allInvoicesIds
        const zip = new JsZip()

        for (const id of allIds) {
          const data = await downloadFile(
            downloadInvoiceRequest(clientIdForRequest, id),
            {
              name: 'invoicesAndQuotations/downloadAllInvoices',
              params: clientId
            }
          )
          zip.file(`invoice_${id}_${today}.pdf`, data, { binary: true })
        }

        const content = await zip.generateAsync({ type: 'blob' })
        uploadFileToBrowser(content, 'invoices', 'zip')
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      commit('setAllInvoicesDownloadingFlag', false)
    }
  },
  createInvoice: async ({ commit, state }, { clientId, quotationId }) => {
    try {
      const requestClientId = getClientIdForRequest(clientId)
      await requests.post(
        createInvoiceRequest(requestClientId, quotationId),
        {}
      )

      await getInvoicesAndQuotations(clientId, commit)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}

const getPageOfInvoices = async (clientId, page, invoicesPerPage) => {
  try {
    const clientIdForRequest = getClientIdForRequest(clientId)
    const pageLimit = invoicesPerPage || 5
    const response = await requests.get(
      invoicesRequest(clientIdForRequest, pageLimit, page)
    )
    return response.data
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

const getInvoicesAndQuotations = async (clientId, commit) => {
  const clientIdForRequest = getClientIdForRequest(clientId)

  commit('setLoadingAll', true)
  const response = await requests.get(
    invoicesAndQuotationsPageRequest(clientIdForRequest, INVOICES_PER_PAGE_DEFAULT_COUNT)
  )

  const data = response.data
  data.quotations = fixAPI(data.quotations)
  data.quotationsPendingInvoice = data.quotationsPendingInvoice
    ? fixAPI(data.quotationsPendingInvoice).map(item => ({ ...item, pending: true }))
    : []

  data.pagination = {
    page: 1,
    itemsPerPage: INVOICES_PER_PAGE_DEFAULT_COUNT
  }

  commit('setLoadingAll', false)
  commit('set', {
    id: clientId,
    info: data
  })
}
