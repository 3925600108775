import { reset, setClientField, setClientInfo, set, setErrors } from '../../../utils/vuex'
import getDefaultState from './defaultState'
const defaultState = getDefaultState()

export default {
  set: setClientInfo(),
  reset: reset(defaultState),
  setEmail: setClientField('email'),
  setLocale: setClientField('locale'),
  setErrors: setErrors(),
  setFullName: setClientField('fullName'),
  setNotifications: setClientField('notifications'),
  setSettingsLoaded: setClientField('settingsLoaded'),
  setBillingDetails: setClientField('billingDetails'),
  setPasswordSetError: set('passwordSetError'),
  setLoadingPasswordSet: set('loadingPasswordSet'),
  setLoadingProfileSetting: set('loadingProfileSetting'),
  setLoadingProfileSettingSet: set('loadingProfileSettingSet')
}
