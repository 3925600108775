import { ALL_PRIORITIES_WITH_VALUES } from '../../../constants/support'
import {
  TICKET_STATE_CANCELED,
  TICKET_STATE_CANCELED_BY_CLIENT,
  TICKET_STATE_DONE
} from '@/constants/app'

export const comparePriorities = (p1, p2) => {
  return ALL_PRIORITIES_WITH_VALUES[p1] > ALL_PRIORITIES_WITH_VALUES[p2] ? 1 : -1
}

export const isClosedTicket = state => {
  return state === TICKET_STATE_DONE ||
    state === TICKET_STATE_CANCELED ||
    state === TICKET_STATE_CANCELED_BY_CLIENT
}
