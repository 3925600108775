const {
  ROUTE_PRODUCT_DETAILS,
  ROUTE_INVOICES_AND_QUOTATIONS,
  ROUTE_BILLING_DETAILS,
  ROUTE_TEAM_MEMBERS,
  ROUTE_PAYMENT_DETAILS
} = require('./routeNames')

const TYPE_PRODUCT = 'Software'
const TYPE_SERVICE = 1

const TYPE_SERVER = 'Server'
const TYPE_CLOUD = 'Cloud'

const STATUS_ISSUED = 'ISSUED'
const STATUS_PAID = 'PAID'

const PAYMENT_METHOD_BANK = 'Bank transfer'
const PAYMENT_METHOD_PAYPAL = 'PayPal'
const PAYMENT_METHOD_CARD = 'Credit card'

const RECURRING_PAYMENTS_ENABLED = 'Enabled'
const RECURRING_PAYMENTS_DISABLED = 'Disabled'

const JWT_NAME = 'jwt'
const ROLE_NAME = 'role'
const PENDING_REQUESTS_NAME = 'pendingRequests'
const PERMISSIONS_NAME = 'permissions'

const DELIMITER = '|'
const INDENT = '-'

const LOGOUT_ICON = 'mdi-logout-variant'
const GENERATE_DOCUMENT_ICON = 'mdi-file-star-outline'
const DOWNLOAD_DOCUMENT_ICON_OUTLINED = 'mdi-file-download-outline'
const DOWNLOAD_DOCUMENT_ICON = 'mdi-file-download'
const INFO_ICON = 'mdi-information'
const SUCCESS_ICON = 'mdi-check-circle'
const ISSUED_ICON = 'radio_button_unchecked'
const PAID_ICON = 'check_circle_outline'
const ADD_USER_ICON = 'person_add'
const EDIT_ICON = 'create'
const PERSON_ICON = 'person'
const LIKE_ICON = 'thumb_up'
const DONE_ICON = 'done'
const CANCELED_ICON = 'highlight_off'
const GO_BACK_ICON = 'keyboard_arrow_left'
const CLOSE_ICON = 'close'
const CHECKED_CIRCLE_ICON = 'mdi-checkbox-marked-circle'
const CLOSED_CIRCLE_ICON = 'mdi-close-circle'
const EXCLAMATION_ICON = 'mdi-exclamation'
const CHECKBOX_MARKED_ICON = 'mdi-checkbox-marked-outline'

const SMALL_WINDOW_SIZES = ['xs', 'sm', 'md']

const TICKET_STATE_ON_CLIENT = 'On client'
const TICKET_STATE_CANCELED_BY_CLIENT = 'Canceled by Client'
const TICKET_STATE_NEW = 'New'
const TICKET_STATE_DONE = 'Done'
const TICKET_STATE_CANCELED = 'Canceled'

const DELETE_ICON = 'delete'

const ROLE_ADMIN = 'partner'
const ROLE_CLIENT = 'client'

const SUBSCRIPTION_MONTHLY = 'monthly'
const SUBSCRIPTION_YEARLY = 'yearly'

const MY_VIEW_PATH_SEGMENT = 'my'

const CHANGE_PLAN_MODAL_WINDOW_STATE_CLOSED = 'closed'
const CHANGE_PLAN_MODAL_WINDOW_STATE_CHANGE = 'changePlan'
const CHANGE_PLAN_MODAL_WINDOW_STATE_WAITING_FOR_QUOTATION_CREATION =
  'waitingForRedirect'
const CHANGE_PLAN_MODAL_WINDOW_QUOTATION_CREATION_TIMEOUT_STATE =
  'quotationTimeout'
const CHANGE_PLAN_MODAL_WINDOW_STATE_CHECKOUT = 'checkout'

const BILLING_DETAIL_LABELS = [
  { key: 'email', labelKey: 'email' },
  { key: 'company', labelKey: 'billing.companyName' },
  { key: 'phone', labelKey: 'telephone' },
  { key: 'street', labelKey: 'billing.street' },
  { key: 'city', labelKey: 'billing.city' },
  { key: 'zip', labelKey: 'billing.zip' },
  { key: 'state', labelKey: 'billing.country' },
  { key: 'registrationNumber', labelKey: 'billing.registrationNumber' },
  { key: 'vatNumber', labelKey: 'billing.vatRegistrationNumber' }
]

const MAX_YEARS_OF_SUBSCRIPTION_EXTENSION = 5

const CLIENT_ID_NAME = 'clientId'
const INVOICES_PER_PAGE_DEFAULT_COUNT = 10

const TIMEOUT_FOR_CREATING_QUOTATION = 15000

const MONTH_PERIOD = 'M'
const DAY_PERIOD = 'D'
const YEAR_PERIOD = 'Y'

const ADMIN_ACCESS_LEVEL = 'admin'
const DOWNLOAD_ACCESS_LEVEL = 'download'
const PURCHASE_ORDERS_ACCESS_LEVEL = 'purchase_orders'
const BILLING_ACCESS_LEVEL = 'billing'
const SUPPORT_ACCESS_LEVEL = 'support'
const CLIENTS_ACCESS_LEVELS = [
  DOWNLOAD_ACCESS_LEVEL,
  PURCHASE_ORDERS_ACCESS_LEVEL,
  BILLING_ACCESS_LEVEL
]
const ACCESS_LEVELS_PERMISSIONS = {}
ACCESS_LEVELS_PERMISSIONS[DOWNLOAD_ACCESS_LEVEL] = [ROUTE_PRODUCT_DETAILS]
ACCESS_LEVELS_PERMISSIONS[PURCHASE_ORDERS_ACCESS_LEVEL] = [
  ROUTE_PRODUCT_DETAILS
]
ACCESS_LEVELS_PERMISSIONS[BILLING_ACCESS_LEVEL] = [
  ROUTE_INVOICES_AND_QUOTATIONS,
  ROUTE_BILLING_DETAILS
]
ACCESS_LEVELS_PERMISSIONS[ADMIN_ACCESS_LEVEL] = [
  ROUTE_TEAM_MEMBERS,
  ROUTE_PAYMENT_DETAILS,
  ROUTE_PRODUCT_DETAILS,
  ROUTE_INVOICES_AND_QUOTATIONS,
  ROUTE_BILLING_DETAILS
]

const ALL_NOTIFICATIONS = 'everything'
const UPDATE_NOTIFICATIONS = 'update_available'
const EXPIRE_NOTIFICATIONS = 'expire_at'
const INVOICE_NOTIFICATIONS = 'invoice_issued'
const PRODUCT_NOTIFICATIONS = 'product_news'

const PRODUCT_DRAWER_WIDTH = '270'
const REQUEST_TYPE_CALLBACK = 'callback'

const REQUEST_TYPE_QUOTATION = 'quotation'

const ACCESS_LEVELS_ENUM = [
  {
    name: ADMIN_ACCESS_LEVEL,
    info: 'adminAccessLevelInfo',
    availableNotifications: [] // make all other access levels
  }, {
    name: DOWNLOAD_ACCESS_LEVEL,
    info: 'downloadAccessLevelInfo',
    availableNotifications: [
      {
        name: UPDATE_NOTIFICATIONS,
        info: 'receiveEmailNotificationsAboutNewVersions'
      }
    ]
  }, {
    name: PURCHASE_ORDERS_ACCESS_LEVEL,
    info: 'purchaseOrdersAccessLevelInfo',
    availableNotifications: [
      {
        name: EXPIRE_NOTIFICATIONS,
        info: 'receiveEmailNotificationsAboutQuotationsOrdersAndExpiration'
      }
    ]
  }, {
    name: BILLING_ACCESS_LEVEL,
    info: 'billingAccessLevelInfo',
    availableNotifications: [
      {
        name: INVOICE_NOTIFICATIONS,
        info: 'receiveEmailNotificationsAboutNewInvoicesAndBillingInfoChanges'
      }
    ]
  }
]

const STATIC_NOTIFICATIONS = [
  {
    name: ALL_NOTIFICATIONS
  },
  {
    name: PRODUCT_NOTIFICATIONS,
    info: 'receiveEmailNotificationsAboutProductNews'
  }
]

const PRICING_TYPE_MIX = 'mix'

export {
  PRICING_TYPE_MIX,
  STATIC_NOTIFICATIONS,
  ACCESS_LEVELS_ENUM,
  ACCESS_LEVELS_PERMISSIONS,
  CLIENTS_ACCESS_LEVELS,
  PRODUCT_DRAWER_WIDTH,
  REQUEST_TYPE_CALLBACK,
  REQUEST_TYPE_QUOTATION,
  MONTH_PERIOD,
  YEAR_PERIOD,
  DAY_PERIOD,
  TYPE_PRODUCT,
  TYPE_SERVICE,
  TYPE_SERVER,
  TYPE_CLOUD,
  STATUS_ISSUED,
  STATUS_PAID,
  PAYMENT_METHOD_BANK,
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_PAYPAL,
  RECURRING_PAYMENTS_ENABLED,
  RECURRING_PAYMENTS_DISABLED,
  JWT_NAME,
  DELIMITER,
  LOGOUT_ICON,
  GENERATE_DOCUMENT_ICON,
  DOWNLOAD_DOCUMENT_ICON,
  DOWNLOAD_DOCUMENT_ICON_OUTLINED,
  INFO_ICON,
  SUCCESS_ICON,
  ISSUED_ICON,
  PAID_ICON,
  ADD_USER_ICON,
  EDIT_ICON,
  SMALL_WINDOW_SIZES,
  PERSON_ICON,
  LIKE_ICON,
  TICKET_STATE_NEW,
  TICKET_STATE_ON_CLIENT,
  TICKET_STATE_DONE,
  TICKET_STATE_CANCELED,
  DONE_ICON,
  CANCELED_ICON,
  DELETE_ICON,
  ROLE_ADMIN,
  ROLE_CLIENT,
  GO_BACK_ICON,
  ROLE_NAME,
  MY_VIEW_PATH_SEGMENT,
  CLOSE_ICON,
  EXCLAMATION_ICON,
  CHECKBOX_MARKED_ICON,
  SUBSCRIPTION_MONTHLY,
  SUBSCRIPTION_YEARLY,
  INDENT,
  CHANGE_PLAN_MODAL_WINDOW_STATE_CHANGE,
  CHANGE_PLAN_MODAL_WINDOW_STATE_CHECKOUT,
  BILLING_DETAIL_LABELS,
  MAX_YEARS_OF_SUBSCRIPTION_EXTENSION,
  CHECKED_CIRCLE_ICON,
  CLOSED_CIRCLE_ICON,
  CLIENT_ID_NAME,
  INVOICES_PER_PAGE_DEFAULT_COUNT,
  CHANGE_PLAN_MODAL_WINDOW_STATE_WAITING_FOR_QUOTATION_CREATION,
  CHANGE_PLAN_MODAL_WINDOW_QUOTATION_CREATION_TIMEOUT_STATE,
  TIMEOUT_FOR_CREATING_QUOTATION,
  CHANGE_PLAN_MODAL_WINDOW_STATE_CLOSED,
  ADMIN_ACCESS_LEVEL,
  DOWNLOAD_ACCESS_LEVEL,
  PURCHASE_ORDERS_ACCESS_LEVEL,
  BILLING_ACCESS_LEVEL,
  SUPPORT_ACCESS_LEVEL,
  ALL_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
  EXPIRE_NOTIFICATIONS,
  INVOICE_NOTIFICATIONS,
  PRODUCT_NOTIFICATIONS,
  PENDING_REQUESTS_NAME,
  PERMISSIONS_NAME,
  TICKET_STATE_CANCELED_BY_CLIENT
}
