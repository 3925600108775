import { requests, getClientIdForRequest } from '../../../utils/requests'
import getApiErrorMessage from '@/utils/getApiErrorMessage'

export default {
  getDataIfNeed: async ({ commit, state }, clientId) => {
    try {
      if (!state.clients[clientId]) {
        const clientIdForRequest = getClientIdForRequest(clientId)
        const response = await requests.get(
          `/clients/${clientIdForRequest}/payments`
        )
        commit('set', {
          id: clientId,
          info: response.data
        })
      }
    } catch (err) {
      const errorKey = getApiErrorMessage(err)
      commit('setError', errorKey)
    }
  },
  cancelRecurringPayment: async ({ commit }, payload) => {
    const clientIdForRequest = getClientIdForRequest(payload.clientId)

    commit('setLoading', 'loading')
    try {
      // eslint-disable-next-line max-len
      await requests.delete(`/clients/${clientIdForRequest}/payments/${payload.paymentId}?feedback=${payload.query.feedback}&reason=${payload.query.reason}`)
      commit('setPaymentEnabled', { id: payload.paymentId, payload: false, clientId: payload.clientId })
    } catch (e) {
      commit('setError', true)
    }
    commit('setLoading', 'loaded')
  },
  redirectToStripeEditing: async ({ commit }, { clientId, paymentId, returnPath }) => {
    commit('setEditingLoading', { field: paymentId, value: true })
    try {
      const clientIdForRequest = getClientIdForRequest(clientId)
      const response = await requests.post(`/clients/${clientIdForRequest}/payments/${paymentId}/edit`,
        { returnUrl: `http://${window.location.hostname}${returnPath}` })

      // eslint-disable-next-line no-undef
      const stripe = Stripe(response.data.stripePublishableKey)
      stripe.redirectToCheckout({ sessionId: response.data.stripeCheckoutSessionId })
    } catch (e) {
      commit('setError', true)
    }
    commit('setEditingLoading', { field: paymentId, value: false })
  }
}
