import router from '../../../router'

export default {
  client: state => {
    return state.clients[router.currentRoute.params.clientId]
  },
  clientById: state => clientId => {
    return Object.prototype.hasOwnProperty.call(state.clients, clientId) ? state.clients[clientId] : {}
  },
  billingDetails: state => (clientId = router.currentRoute.params.clientId) => {
    return Object.prototype.hasOwnProperty.call(state.clients, clientId)
      ? state.clients[clientId].billingDetails
      : {}
  },
  getBillingDetailDataById: state => clientId => (
    Object.prototype.hasOwnProperty.call(state.clients, clientId)
      ? state.clients[clientId].billingDetails.billing
      : {}
  ),
  getPartnerBillingDetailDataById: state => clientId => (
    Object.prototype.hasOwnProperty.call(state.clients, clientId)
      ? state.clients[clientId].partnerBillingDetails.billing
      : {}
  ),
  userManager: state => state.clients[router.currentRoute.params.clientId]?.manager,
  hasExternalPartner: state => (
    Object.prototype.hasOwnProperty.call(state.clients, router.currentRoute.params.clientId) &&
    Object.prototype.hasOwnProperty.call(state.clients[router.currentRoute.params.clientId], 'hasExternalPartner')
      ? state.clients[router.currentRoute.params.clientId].hasExternalPartner
      : true
  )
}
