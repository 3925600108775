import getDefaultState from './defaultState'
import {
  reset,
  set
} from '@/utils/vuex'

const defaultState = getDefaultState()

export default {
  setDownloadingPackage: set('downloadingPackage'),
  setLoadingPlugins: set('loadingPlugins'),
  setAvailablePlugins: set('availablePlugins'),
  setChangePlanModalWindowState: set('changePlanModalWindowState'),
  setLoadingOrderSummary: set('loadingOrderSummary'),
  reset: reset(defaultState)
}
