import { result } from 'lodash'
import { getClientIdForRequest, requests } from '@/utils/requests'
import getFormErrorMessages from '@/utils/getFormErrorMessages'

const updateDataActionName = 'client/getDataById'
const billingRequest = (clientId) => `/clients/${clientId}/billing-details`

const editBillingDetails = async (commit, newBillingDetails, clientId) => {
  try {
    commit('setLoading', true)
    commit('setErrors', {})
    const clientIdForRequest = getClientIdForRequest(clientId)

    const billingStateId = result(newBillingDetails, 'billing.state.id')
    const contactStateId = result(newBillingDetails, 'contact.state.id')
    newBillingDetails.billing.state = billingStateId
    newBillingDetails.contact.state = contactStateId

    const response = await requests.put(
      billingRequest(clientIdForRequest),
      newBillingDetails,
      {
        name: updateDataActionName,
        params: clientId
      }
    )

    window.console.log('SUCCESS', response)
    commit(
      'client/setBillingDetails',
      { id: clientId, payload: response.data },
      { root: true }
    )
  } catch (e) {
    const errors = getFormErrorMessages(e)

    window.console.log('ERROR', errors)
    commit('setErrors', errors)
  }

  commit('setLoading', false)
}

export default {
  setBillingDetails: ({ commit, rootState }, { clientId, ...requestPayload }) => {
    const newBillingDetails = {
      ...rootState.client.clients[clientId].billingDetails,
      billing: requestPayload
    }

    if (newBillingDetails.contactSameAsBilling) {
      newBillingDetails.contact = newBillingDetails.billing
    }

    editBillingDetails(commit, newBillingDetails, clientId)
  },
  setContactDetails: ({ commit, rootState }, { clientId, ...requestPayload }) => {
    const newBillingDetails = {
      ...rootState.client.clients[clientId].billingDetails,
      contactSameAsBilling: false,
      contact: requestPayload
    }

    editBillingDetails(commit, newBillingDetails, clientId)
  },
  setContactSameAsBilling: ({ commit, rootState }, { clientId, contactSameAsBilling }) => {
    const newBillingDetails = {
      ...rootState.client.clients[clientId].billingDetails,
      contactSameAsBilling
    }

    if (newBillingDetails.contactSameAsBilling) {
      newBillingDetails.contact = newBillingDetails.billing
    }

    editBillingDetails(commit, newBillingDetails, clientId)
  },
  getCountryCodesIfNeed: async ({ commit, state }) => {
    if (!state.countryCodes) {
      commit('setErrors', {})
      commit('setCountryCodesLoading', true)
      try {
        const response = await requests.get('/country-codes')
        commit('setCountryCodes', response.data)
      } catch (e) {
        commit('setErrors', getFormErrorMessages(e))
      }
      commit('setCountryCodesLoading', false)
    }
  }
}
