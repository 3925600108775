import { requests, setRequestsAuthHeader } from '../../../utils/requests'
import {
  JWT_NAME,
  MY_VIEW_PATH_SEGMENT,
  ROLE_CLIENT,
  ROLE_NAME,
  CLIENT_ID_NAME,
  PERMISSIONS_NAME
} from '../../../constants/app'
import {
  authenticationByLoginRequest,
  authenticationByTokenRequest,
  authenticationResetPasswordRequest
} from '../../../utils/requestsTemplates'
import fixContractsAPI from '../contract/fixAPI'
import fixClientAPI from '../client/fixAPI'

const tryToLoginByEmailAndPassword = async (email, password, commit) => {
  const response = await requests.post(authenticationByLoginRequest, {
    login: email,
    password: password
  })

  if (response.data && response.data.contracts) {
    response.data.contracts = fixContractsAPI(response.data.contracts)
  }

  if (response.data.role === ROLE_CLIENT) {
    response.data = fixClientAPI(response.data)
  }

  commit('setError', null)
  return response
}

const tryToLoginByToken = async token => {
  const response = await requests.post(authenticationByTokenRequest, {
    token: token
  })

  if (response.data && response.data.contracts) {
    response.data.contracts = fixContractsAPI(response.data.contracts)
  }

  if (response.data.role === ROLE_CLIENT) {
    response.data = fixClientAPI(response.data)
  }

  return response
}

const resetPassword = async (email) => {
  return await requests.post(authenticationResetPasswordRequest, {
    email
  })
}

const applyUserData = (data, commit) => {
  setLocalStorageData(data)
  commit('setRole', data.role)
  setRequestsAuthHeader(data[JWT_NAME])
  if (data.role === ROLE_CLIENT) {
    setStoreAfterClientLogin(commit, data)
  }
}

const resetUserData = (commit) => {
  resetLocalStorageData()
  commit('setRole', null)
}

export default {
  tryToLoginByEmailAndPassword,
  tryToLoginByToken,
  applyUserData,
  resetUserData,
  resetPassword
}

const setLocalStorageData = data => {
  localStorage.setItem(JWT_NAME, data.jwt)
  localStorage.setItem(ROLE_NAME, data.role)
  localStorage.setItem(CLIENT_ID_NAME, data.guid)
  localStorage.setItem(PERMISSIONS_NAME, JSON.stringify(data.permissions))
}

const resetLocalStorageData = () => {
  localStorage.removeItem(JWT_NAME)
  localStorage.removeItem(ROLE_NAME)
  localStorage.removeItem(CLIENT_ID_NAME)
  localStorage.removeItem(PERMISSIONS_NAME)
}

const setStoreAfterClientLogin = (commit, data) => {
  commit('contract/set', { info: data.contracts, id: MY_VIEW_PATH_SEGMENT }, { root: true })
  commit('invoicesAndQuotations/set', { info: { invoices: data.invoices }, id: MY_VIEW_PATH_SEGMENT }, { root: true })
  commit('recurringPayments/set', { info: data.payments, id: MY_VIEW_PATH_SEGMENT }, { root: true })
  delete data.contracts
  delete data.invoices
  commit('client/set', { info: data, id: MY_VIEW_PATH_SEGMENT }, { root: true })
}
