import { isClosedTicket } from './service'
import { getClientIdForRequest, requests } from '@/utils/requests'
import router from '@/router'
import {
  ticketsRequest,
  requestsRequest,
  supportLinksRequest,
  supportManualsRequest,
  dealRegistrationRequest
} from '@/utils/requestsTemplates'
import { downloadFile, uploadFileToBrowser } from '@/utils/download'
import getApiErrorMessage from '@/utils/getApiErrorMessage'
import getFormErrorMessages from '@/utils/getFormErrorMessages'

const updateDataActionName = 'support/getData'

const splitTickets = (tickets) => {
  const openTickets = []
  const closedTickets = []

  for (const ticket of tickets.data) {
    if (isClosedTicket(ticket.state)) {
      closedTickets.push(ticket)
    } else {
      openTickets.push(ticket)
    }
  }

  return { openTickets, closedTickets }
}

const getData = async (commit, clientId) => {
  const clientIdForRequest = getClientIdForRequest(clientId)
  const responseTickets = await requests.get(ticketsRequest(clientIdForRequest))
  const responseRequests = await requests.get(requestsRequest(clientIdForRequest))
  const responseLinks = await requests.get(supportLinksRequest(clientIdForRequest))

  const tickets = splitTickets(responseTickets)

  commit('set', {
    id: clientId,
    info: {
      requests: responseRequests.data,
      openTickets: tickets.openTickets,
      closedTickets: tickets.closedTickets,
      links: responseLinks.data
    }
  })
}

const getTickets = async (state, commit, clientId, searchParams = null) => {
  const clientIdForRequest = getClientIdForRequest(clientId)
  const responseTickets = await requests.get(ticketsRequest(clientIdForRequest), searchParams)

  const tickets = splitTickets(responseTickets)

  commit('set', {
    id: clientId,
    info: {
      requests: state.clients[clientId].requests,
      openTickets: tickets.openTickets,
      closedTickets: tickets.closedTickets,
      links: state.clients[clientId].links
    }
  })
}

const convertToFormData = (data) => {
  const formData = new FormData()

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (Array.isArray(data[key])) {
        data[key].forEach((item, index) => {
          formData.set(`${key}[${index}]`, item)
        })
      } else {
        formData.set(key, data[key])
      }
    }
  }

  return formData
}

export default {
  getDataIfNeed: async ({ commit, state }, clientId) => {
    if (!Object.prototype.hasOwnProperty.call(state.clients, clientId)) {
      return getData(commit, clientId)
    }
  },
  getData: async ({ commit }, clientId) => {
    await getData(commit, clientId)
  },
  getTickets: async ({ state, commit }, { clientId, searchParams }) => {
    await getTickets(state, commit, clientId, searchParams)
  },
  downloadOwnersManual: async ({ state }, clientId) => {
    const clientIdForRequest = getClientIdForRequest(clientId)
    const data = await downloadFile(supportManualsRequest(clientIdForRequest))

    uploadFileToBrowser(data, 'owners_manual', 'pdf')
  },
  downloadTicketAttachment: async ({ commit, state }, { fileName, url, loadingId }) => {
    commit('setAttachmentsLoading', loadingId)
    try {
      const data = await downloadFile(url)

      const name = fileName.split('.')[0]
      const type = fileName.split('.')[1]
      uploadFileToBrowser(data, name, type)
    } catch (e) {
      commit('setErrors', 'An error occurred during file download')
    }
    commit('setAttachmentsLoading', false)
  },
  createTicket: async ({ commit, state }, { clientId, ...requestPayload }) => {
    const clientIdForRequest = getClientIdForRequest(clientId)
    const formData = convertToFormData(requestPayload)

    try {
      commit('setLoading', true)
      commit('setNewTicketSubmitted', true)

      const response = await requests.post(ticketsRequest(clientIdForRequest), formData, {
        name: updateDataActionName,
        params: clientId
      })
      commit('appendOpenTicket', { clientId, info: response.data })
    } catch (e) {
      commit('setErrors', getFormErrorMessages(e))
    }

    commit('setLoading', false)
  },
  escalateTicket: async ({ commit }, ticket) => {
    try {
      commit('setErrors', {})
      const routerClientId = router.currentRoute.params.clientId
      const clientIdForRequest = getClientIdForRequest(routerClientId)
      const response = await requests.post(
        `/clients/${clientIdForRequest}/tickets/${ticket.id}/escalate`,
        {},
        {
          name: updateDataActionName,
          params: routerClientId
        }
      )

      commit('replaceTicket', {
        clientId: routerClientId,
        id: ticket.id,
        payload: response.data
      })
    } catch (err) {
      commit('setError', getApiErrorMessage(err))
    }
  },
  closeTicket: async ({ commit }, ticket) => {
    try {
      commit('setErrors', {})
      const routerClientId = router.currentRoute.params.clientId
      const clientIdForRequest = getClientIdForRequest(routerClientId)

      const response = await requests.post(
        `/clients/${clientIdForRequest}/tickets/${ticket.id}/close`,
        {},
        {
          name: updateDataActionName,
          params: routerClientId
        }
      )

      commit('removeOpenTicket', {
        id: ticket.id,
        clientId: routerClientId
      })

      commit('appendClosedTicket', {
        clientId: routerClientId,
        info: response.data
      })
    } catch (err) {
      commit('setError', getApiErrorMessage(err))
    }
  },
  getTicketComments: async ({ commit, state }, { clientId, ticketId }) => {
    const intTicketId = parseInt(ticketId)
    commit('setTicketCommentsLoading', true)
    try {
      const clientIdForRequest = getClientIdForRequest(clientId)
      const response = await requests.get(`/clients/${clientIdForRequest}/tickets/${ticketId}/comments`)
      commit('setTicketComments', {
        clientId: clientId,
        property: intTicketId,
        payload: response.data
      })
    } catch (err) {
      commit('setError', getApiErrorMessage(err))
    }
    commit('setTicketCommentsLoading', false)
  },
  postTicketComment: async ({ commit, state, rootState }, { clientId, ticketId, data, newCommentId }) => {
    commit('setLoading', true)
    const intTicketId = parseInt(ticketId)

    try {
      const clientIdForRequest = getClientIdForRequest(clientId)
      const formData = convertToFormData(data)

      const response = await requests.post(`/clients/${clientIdForRequest}/tickets/${ticketId}/comments`,
        formData, {
          name: updateDataActionName,
          params: clientId
        })

      commit('replaceTicket', {
        clientId: clientId,
        id: intTicketId,
        payload: response.data
      })
      commit('appendTicketComment', {
        clientId: clientId,
        property: intTicketId,
        payload: {
          id: newCommentId,
          text: data.text,
          createDateTime: new Date().toISOString(),
          userName: rootState.client.clients[clientId].fullName
        }
      })
    } catch (e) {
      commit('setErrors', getFormErrorMessages(e))
    }
    commit('setLoading', false)
  },
  createRequest: async ({ commit, state }, { clientId, ...requestPayload }) => {
    const clientIdForRequest = getClientIdForRequest(clientId)

    commit('setErrors', {})
    commit('setLoading', true)
    try {
      await requests.post(
        requestsRequest(clientIdForRequest),
        requestPayload,
        {
          name: updateDataActionName,
          params: clientId
        }
      )

      if (Object.prototype.hasOwnProperty.call(state.clients, clientId)) {
        commit('appendRequest', {
          clientId: clientId,
          info: requestPayload
        })
      }
    } catch (e) {
      commit('setErrors', getFormErrorMessages(e))
    }
    commit('setLoading', false)
  },
  sendDealRegistration: async ({ commit, state }, requestPayload) => {
    try {
      commit('setLoading', true)
      commit('setDealRegistrationSubmitted', false)

      await requests.post(dealRegistrationRequest, requestPayload)

      commit('setDealRegistrationSubmitted', true)
    } catch (e) {
      commit('setErrors', getFormErrorMessages(e))
    }

    commit('setLoading', false)
  }
}
