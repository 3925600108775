import { getClientIdForRequest, requests } from '../../../utils/requests'
import router from '../../../router'
import { membersRequest } from '../../../utils/requestsTemplates'
import fixAPI from './fixAPI'
import getFormErrorMessages from '@/utils/getFormErrorMessages'
const updateDataAction = {
  name: 'member/getData',
  params: {}
}

const getData = async (commit, clientId) => {
  const clientIdForRequest = getClientIdForRequest(clientId)
  const response = await requests.get(membersRequest(clientIdForRequest))
  if (response.data) {
    response.data = response.data.map(member => fixAPI(member))
    commit('set', { id: clientId, info: { members: response.data } })
  }
}

export default {
  getDataIfNeed: async ({ commit, state }, clientId) => {
    if (!state.clients[clientId]) {
      await getData(commit, clientId)
    }
  },
  getData: async ({ commit }) => {
    const clientId = router.currentRoute.params.clientId
    await getData(commit, clientId)
  },
  createTeamMember: async ({ commit }, { clientId, ...requestPayload }) => {
    try {
      const clientIdForRequest = getClientIdForRequest(clientId)

      commit('setErrors', {})
      commit('setLoadingTeamMember', true)
      const response = await requests.post(membersRequest(clientIdForRequest), requestPayload, updateDataAction)

      commit('appendTeamMember', { clientId, info: fixAPI(response.data) })
    } catch (e) {
      commit('setErrors', getFormErrorMessages(e))
    }

    commit('setLoadingTeamMember', false)
  },
  editTeamMember: async ({ commit }, { clientId, guid, ...requestPayload }) => {
    try {
      const clientIdForRequest = getClientIdForRequest(clientId)

      commit('setErrors', {})
      commit('setLoadingTeamMember', true)
      const response = await requests.put(
        membersRequest(clientIdForRequest, guid),
        requestPayload,
        updateDataAction
      )
      commit('replaceTeamMember', {
        clientId,
        id: guid,
        payload: fixAPI(response.data)
      })
    } catch (e) {
      commit('setErrors', getFormErrorMessages(e))
    }

    commit('setLoadingTeamMember', false)
  },
  deleteTeamMember: async ({ dispatch, commit, state }, memberId) => {
    try {
      const clientIdForRequest = getClientIdForRequest(
        router.currentRoute.params.clientId
      )
      await requests.delete(
        membersRequest(clientIdForRequest, memberId),
        {},
        updateDataAction
      )

      commit('removeTeamMember', {
        id: memberId,
        clientId: router.currentRoute.params.clientId
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}
