import { requests } from '@/utils/requests'
import getFormErrorMessages from '@/utils/getFormErrorMessages'

export default {
  getCountryCodesIfNeed: async ({ commit, state }) => {
    if (!state.countryCodes) {
      commit('setErrors', {})
      commit('setLoading', true)
      try {
        const response = await requests.get('/country-codes')
        commit('setCountryCodes', response.data)
      } catch (e) {
        commit('setErrors', getFormErrorMessages(e))
      }
      commit('setLoading', false)
    }
  }
}
