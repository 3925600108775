import { getClientIdForRequest, requests } from '../../../utils/requests'
import { pendingRequestsRequest } from '../../../utils/requestsTemplates'
import router from '../../../router'
const PENDING_STATUS = 'pending'
// const COMPLETED_STATUS = 'completed'
// const FAILED_STATUS = 'failed'

export default {
  checkRequestStatus: async (
    { dispatch, commit, state },
    { request, interval }
  ) => {
    const clientIdForRequest = getClientIdForRequest(
      router.currentRoute.params.clientId
    )
    try {
      const response = await requests.get(
        pendingRequestsRequest(clientIdForRequest, request.id)
      )

      if (response.data.status !== PENDING_STATUS) {
        commit('removeRequest', request.id)
        clearInterval(interval)
        dispatch(request.updateDataAction.name, request.updateDataAction.params, { root: true })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Can not check status of pending request')
    }
  }
}
