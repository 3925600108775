import {
  removeFromClientArray,
  toggle,
  setValue,
  setClientInfo,
  reset,
  set,
  pushToClientArray,
  appendToClientArray,
  setClientField,
  setClientFieldProperty,
  appendToClientFieldProperty,
  setErrors
} from '../../../utils/vuex'
import getDefaultState from './defaultState'
import { isClosedTicket } from './service'
const defaultState = getDefaultState()

export default {
  set: setClientInfo(),
  setLoading: set('loading'),
  setAttachmentsLoading: set('attachmentsLoading'),
  setTicketCommentsLoading: set('commentsLoading'),
  setEditTicketDescriptionLoading: set('editTicketDescriptionLoading'),
  setErrors: setErrors(),
  setRequests: setClientField('requests'),
  setLinks: setClientField('links'),
  toggleShowTickets: toggle('showClosedTickets'),
  toggleTicketSubmittedFlag: toggle('newTicketSubmitted'),
  setNewTicketSubmitted: set('newTicketSubmitted'),
  clearNewCallbackRequestFields: setValue('newCallbackRequestSubmitted', false),
  removeOpenTicket: removeFromClientArray('openTickets'),
  appendClosedTicket: pushToClientArray('closedTickets'),
  appendOpenTicket: pushToClientArray('openTickets'),
  replaceTicket: (state, data) => {
    if (state.clients[data.clientId].openTickets.find(ticket => ticket.id === data.id)) {
      if (isClosedTicket(data.payload)) { // move from open to closed tickets
        state.clients[data.clientId].closedTickets = [...state.clients[data.clientId].closedTickets, data.payload]
        state.clients[data.clientId].openTickets = state.clients[data.clientId].closedTickets.filter(
          ticket => ticket.id !== data.id
        )
      } else { // replace in open tickets
        state.clients[data.clientId].openTickets = state.clients[data.clientId].openTickets.map(ticket => {
          if (ticket.id === data.id) {
            return data.payload
          }
          return ticket
        })
      }
    } else {
      if (isClosedTicket(data.payload)) { // replace in closed tickets
        state.clients[data.clientId].closedTickets = state.clients[data.clientId].closedTickets.map(ticket => {
          if (ticket.id === data.id) {
            return data.payload
          }
          return ticket
        })
      } else { // move from closed to open tickets
        state.clients[data.clientId].openTickets = [...state.clients[data.clientId].openTickets, data.payload]
        state.clients[data.clientId].closedTickets = state.clients[data.clientId].closedTickets.filter(
          ticket => ticket.id !== data.id
        )
      }
    }
  },
  appendRequest: appendToClientArray('requests'),
  setTicketComments: setClientFieldProperty('comments'),
  appendTicketComment: appendToClientFieldProperty('comments'),
  reset: reset(defaultState),
  setDealRegistrationSubmitted: set('dealRegistrationSubmitted')
}
