<template>
  <div class="reset-password-container">
    <v-card
      class="reset-password-card"
      data-cy="reset-password-card"
      :loading="loading"
    >
      <div class="reset-password-card-brand">
        <v-img
          :src="logo"
          width="250px"
          contain
        />
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="reset-password-card-subtitle"
        v-html="resetPasswordSubtitle"
      />
      <!-- eslint-enable vue/no-v-html -->

      <v-form
        ref="form"
        @submit.prevent="submitForm"
      >
        <v-card-text>
          <v-alert
            v-if="error"
            dense
            outlined
            type="error"
            data-cy="reset-password-alert"
          >
            {{ error }}
          </v-alert>

          <v-alert
            v-if="resetPasswordSent"
            dense
            outlined
            type="success"
            data-cy="reset-password-send"
          >
            {{ $t('login.resetPasswordSent') }}
          </v-alert>

          <v-text-field
            v-if="!resetPasswordSent"
            v-model="data.email"
            :label="$t('email')"
            :rules="emailRules"
            required
            prepend-icon="person"
            data-cy="reset-password-email"
          />

          <div class="text-right mb-4">
            <router-link
              :to="{ name: routeLogin, params: { locale: $route.params.locale } }"
            >
              {{ $t('login.login') }}
            </router-link>
          </div>

          <v-alert
            outlined
            text
            color="info"
          >
            <!--eslint-disable-next-line vue/no-v-html-->
            <small v-html="resetPasswordNote" />
          </v-alert>
        </v-card-text>

        <v-card-actions class="px-4">
          <div class="mr-n3">
            <core-element-localization-selector />
          </div>
          <v-spacer />
          <v-btn
            v-if="!resetPasswordSent"
            type="submit"
            depressed
            x-large
            rounded
            color="primary"
            data-cy="reset-password-submit"
          >
            {{
              $t('login.resetPassword')
            }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ROUTE_LOGIN } from '@/constants/routeNames'
import { validateEmail } from '@/utils/validations'

export default {
  data () {
    return {
      logo: require('../images/Logo_Easy_Software.svg'),
      background: require('../images/ep-login-bg.png'),
      data: {},
      resetPasswordNote: this.$t('login.loginNotice'),
      resetPasswordSubtitle: this.$t('login.loginForm'),
      routeLogin: ROUTE_LOGIN
    }
  },
  computed: {
    ...mapState('login', ['error', 'loading', 'resetPasswordSent']),
    emailRules () {
      return [
        v => !!v || this.$i18n.t('errors.fieldIsRequired'),
        v => validateEmail(v) || this.$i18n.t('errors.wrongEmailFormat')
      ]
    }
  },
  mounted () {
    this.resetPassword()
  },
  methods: {
    ...mapActions('login', ['resetPassword', 'sendResetPassword']),
    submitForm () {
      const validForm = this.$refs.form.validate()

      if (validForm) {
        this.sendResetPassword(this.data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: -16px;
  box-sizing: content-box;
  position: sticky;

  &:after,&:before{
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    bottom:0;
  }
  &:before{
    background: url("../images/ep-login-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -2;
  }
  &:after{
    background: radial-gradient(ellipse at center, rgba(242,242,242,0) 0%, rgba(242,242,242,0.5) 100%);
    z-index: -1;
  }
  & > div{
    box-sizing: border-box;
  }

}

.reset-password-card {
  width: 40em;
  padding: 24px;

  &#{&}{
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%), 0 3px 3px rgb(0 0 0 / 10%) !important;
  }
  border-radius: 3px;
  &-brand{
    padding: 24px;
    display: flex;
    justify-content: center;
    &>div{
      flex-grow: 0;
    }

  }
}

.reset-password-card-subtitle {
  text-align: center;
}
</style>
