import Vue from 'vue'
import { upperFirst, camelCase } from 'lodash'

const requireComponent = require.context('./', true, /\.vue$/, 'lazy')

requireComponent.keys().forEach(fileName => {
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
  )

  Vue.component(componentName, () => import(`./${fileName.replace('./', '')}`))
})
