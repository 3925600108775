import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import { Trans } from '../plugins/Translation'
import {
  JWT_NAME,
  ROLE_NAME,
  MY_VIEW_PATH_SEGMENT,
  ROLE_ADMIN,
  ACCESS_LEVELS_PERMISSIONS,
  PERMISSIONS_NAME,
  ROLE_CLIENT
} from '../constants/app'
import {
  ROUTE_LOGIN,
  ROUTE_OVERVIEW,
  ROUTE_PRODUCT_DETAILS,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_PAYMENT_CANCELED,
  ROUTE_SUPPORT_CENTER,
  ROUTE_TICKET_MODAL,
  ROUTE_TEAM_MEMBERS,
  ROUTE_INVOICES_AND_QUOTATIONS,
  ROUTE_PAYMENT_DETAILS,
  ROUTE_RESET_PASSWORD
} from '../constants/routeNames'
import store from '../store'
import routes from './routes'
const DEFAULT_PERMITTED_ROUTES = [
  ROUTE_OVERVIEW,
  ROUTE_LOGIN,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_PAYMENT_CANCELED,
  ROUTE_SUPPORT_CENTER,
  ROUTE_TICKET_MODAL
]

Vue.use(Router)
Vue.use(Meta)

const tryToLoginWithToken = async (token, to, next) => {
  try {
    await store.dispatch('login/loginByToken', token)

    if (to.name === ROUTE_LOGIN) {
      next({
        path: `${to.params.locale}/${MY_VIEW_PATH_SEGMENT}/overview`,
        name: ROUTE_OVERVIEW,
        params: {
          ...to.params,
          clientId: MY_VIEW_PATH_SEGMENT
        }
      })
    } else {
      next(to)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    next(to)
  }
}

const getClientDataIfNeed = async routeClientId => {
  await store.dispatch('client/getDataByIdIfNeed', routeClientId)
  await store.dispatch('client/getClientSettingsIfNeed', routeClientId)
}

const isRoutePermitted = (clientId, routeName) => {
  if (DEFAULT_PERMITTED_ROUTES.includes(routeName)) {
    return true
  }
  let permitted = false
  const clientPermissions = JSON.parse(localStorage.getItem(PERMISSIONS_NAME))
  for (const permission of clientPermissions) {
    if (ACCESS_LEVELS_PERMISSIONS[permission].includes(routeName)) {
      permitted = true
      break
    }
  }
  return permitted
}

const redirectToOverview = (to, next, clientId = MY_VIEW_PATH_SEGMENT) => {
  const overviewPath = `/${to.params.locale}/${MY_VIEW_PATH_SEGMENT}/overview`
  next({
    path: overviewPath,
    params: {
      locale: to.params.locale,
      clientId: clientId
    },
    name: ROUTE_OVERVIEW
  })
}

const redirectToValidRouteForClient = async (to, next) => {
  if (!to.name || !isRoutePermitted(to.params.clientId, to.name)) {
    redirectToOverview(to, next, to.params.clientId)
  } else {
    switch (to.name) {
      case ROUTE_OVERVIEW: {
        // if client redirect to he's overview or admin redirect to client overview
        if (localStorage.getItem(ROLE_NAME) === ROLE_CLIENT ||
          to.params.clientId !== MY_VIEW_PATH_SEGMENT) {
          await store.dispatch('contract/getDataIfNeed', to.params.clientId)
          await store.dispatch('invoicesAndQuotations/getOverviewData', to.params.clientId)
          await store.dispatch('member/getDataIfNeed', to.params.clientId)
          await store.dispatch('recurringPayments/getDataIfNeed', to.params.clientId)
        }
        break
      }
      case ROUTE_TEAM_MEMBERS: {
        await store.dispatch('member/getDataIfNeed', to.params.clientId)
        break
      }
      case ROUTE_INVOICES_AND_QUOTATIONS: {
        await store.dispatch('invoicesAndQuotations/getInvoicesAndQuotationsIfNeed', to.params.clientId)
        break
      }
      case ROUTE_PAYMENT_DETAILS: {
        await store.dispatch('recurringPayments/getDataIfNeed', to.params.clientId)
        break
      }
      case ROUTE_PRODUCT_DETAILS: {
        await store.dispatch('contract/getDataIfNeed', to.params.clientId)
        const clientsContracts = store.getters['contract/client'](to.params.clientId)
        if (
          clientsContracts &&
          clientsContracts.length &&
          (!to.params.contractId || !clientsContracts.find(c => c.id === decodeURI(to.params.contractId)))
        ) {
          to.params.contractId = clientsContracts[0].id
          next(to)
        }
        break
      }
      case ROUTE_LOGIN: {
        redirectToOverview(to, next, to.params.clientId)
        break
      }
      case ROUTE_TICKET_MODAL:
        await store.dispatch('support/getDataIfNeed', to.params.clientId)
        await store.dispatch('support/getTicketComments', {
          clientId: to.params.clientId,
          ticketId: to.params.ticketId
        })
        break
      case ROUTE_SUPPORT_CENTER:
        await store.dispatch('support/getDataIfNeed', to.params.clientId)
        break
    }
    await store.commit('layout/setLoading', { to: to.name, value: false })
    next()
  }
}

const redirectToClientLocale = (to, next, clientLocale) => {
  const stringBetweenSlashesRegex = /\/.*?\//g
  const pathSegmentsCount = ((to.path || '').match(stringBetweenSlashesRegex) || []).length
  let newPath = ''
  if (pathSegmentsCount > 0) {
    newPath = to.path.replace(/\/.*?\//, `/${clientLocale}/`)
  } else {
    newPath = `/${clientLocale}`
  }
  to = {
    ...to,
    path: newPath,
    fullPath: newPath,
    params: { ...to.params, locale: clientLocale }
  }
  next(to)
}

const redirectToValidRouteForAdmin = (to, next) => {
  if (to.params.clientId === MY_VIEW_PATH_SEGMENT) {
    if (to.name !== ROUTE_OVERVIEW) {
      redirectToOverview(to, next)
    } else {
      next()
    }
  } else {
    redirectToValidRouteForClient(to, next)
  }
}

const redirectToValidRouteForRegisteredUser = (to, next) => {
  const clientLocale = store.state.client.clients[to.params.clientId].locale || Trans.getUserSupportedLang()
  if (clientLocale && to.params.locale && to.params.locale !== clientLocale) {
    redirectToClientLocale(to, next, clientLocale)
  } else {
    if (localStorage.getItem(ROLE_NAME) === ROLE_ADMIN) {
      redirectToValidRouteForAdmin(to, next)
    } else {
      if (to.params.clientId !== MY_VIEW_PATH_SEGMENT) {
        to.params.clientId = MY_VIEW_PATH_SEGMENT
      }
      redirectToValidRouteForClient(to, next)
    }
  }
}

const redirectToLoginPage = (to, next) => {
  if (to.name !== ROUTE_LOGIN) {
    const loginPath = `/${to.params.locale}/login`
    next({
      path: loginPath,
      params: { locale: to.params.locale },
      name: ROUTE_LOGIN
    })
  } else {
    next()
  }
}

const redirectToResetPasswordPage = (to, next) => {
  if (to.name !== ROUTE_RESET_PASSWORD) {
    const loginPath = `/${to.params.locale}/reset-password`
    next({
      path: loginPath,
      params: { locale: to.params.locale },
      name: ROUTE_RESET_PASSWORD
    })
  } else {
    next()
  }
}

function createRouter () {
  const router = new Router({
    mode: 'history',
    routes: routes,
    scrollBehavior (to, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }
      if (to.hash) {
        return { selector: to.hash }
      }
      return { x: 0, y: 0 }
    }
  })

  router.beforeEach(async (to, from, next) => {
    try {
      if (to.query.token) {
        await tryToLoginWithToken(
          to.query.token,
          { ...to, query: {} }, // delete token from query to prevent cycle
          next
        )
      } else {
        if (to.name) {
          store.commit('layout/setLoading', { to: to.name, value: true })
        }
        to.params.clientId = to.params.clientId || MY_VIEW_PATH_SEGMENT
        if (localStorage.getItem(JWT_NAME)) {
          await getClientDataIfNeed(to.params.clientId)
          redirectToValidRouteForRegisteredUser(to, next)
        } else if (to.name === ROUTE_RESET_PASSWORD) {
          store.commit('layout/setLoading', { to: to.name, value: false })
          to.params.locale = to.params.locale || Trans.getUserSupportedLang()
          redirectToResetPasswordPage(to, next)
        } else {
          store.commit('layout/setLoading', { to: to.name, value: false })
          to.params.locale = to.params.locale || Trans.getUserSupportedLang()
          redirectToLoginPage(to, next)
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  })

  return router
}

const router = createRouter()
export default router
