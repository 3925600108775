<template>
  <div class="login-container">
    <v-card
      class="login-card"
      data-cy="login-card"
      :loading="loading"
    >
      <div class="login-card-brand">
        <v-img
          :src="logo"
          width="250px"
          contain
        />
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="login-card-subtitle"
        v-html="loginSubtitle"
      />
      <!-- eslint-enable vue/no-v-html -->

      <v-form @submit.prevent="submitForm">
        <v-card-text>
          <v-alert
            v-if="error"
            dense
            outlined
            type="error"
            data-cy="login-alert"
          >
            {{ error }}
          </v-alert>
          <v-text-field
            v-model="loginData.email"
            :label="$t('email')"
            placeholder=" "
            name="e-mail"
            prepend-icon="person"
            type="text"
            data-cy="login-name"
          />

          <v-text-field
            id="password"
            v-model="loginData.password"
            :label="$t('password')"
            placeholder=" "
            name="password"
            prepend-icon="lock"
            type="password"
            data-cy="login-password"
          />

          <div class="text-right mb-4">
            <router-link
              :to="{ name: routeResetPassword, params: { locale: $route.params.locale } }"
              data-cy="link-reset-password"
            >
              {{ $t('login.forgotPassword') }}
            </router-link>
          </div>

          <v-alert
            outlined
            text
            color="info"
          >
            <!--eslint-disable-next-line vue/no-v-html-->
            <small v-html="loginNote" />
          </v-alert>
        </v-card-text>

        <v-card-actions class="px-4">
          <div class="mr-n3">
            <core-element-localization-selector />
          </div>
          <v-spacer />
          <v-btn
            type="submit"
            depressed
            x-large
            rounded
            color="primary"
            :loading="loading"
            data-cy="login-user"
          >
            {{
              $t('login.login')
            }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ROUTE_RESET_PASSWORD } from '@/constants/routeNames'

export default {
  data () {
    return {
      logo: require('../images/Logo_Easy_Software.svg'),
      background: require('../images/ep-login-bg.png'),
      loginData: {},
      loginNote: this.$t('login.loginNotice'),
      loginSubtitle: this.$t('login.loginForm'),
      routeResetPassword: ROUTE_RESET_PASSWORD
    }
  },
  computed: {
    ...mapState('login', ['error', 'loading'])
  },
  mounted() {
    setTimeout(() => {
      const autofillInputs = document.querySelectorAll("input:-webkit-autofill");
      autofillInputs.forEach((input) => {
        const label = input.parentElement.querySelector("label");
        label.classList.add("v-label--active");
      });
    }, 500);
  },
  methods: {
    ...mapActions('login', ['submit']),
    submitForm () {
      this.submit(this.loginData)
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: -16px;
  box-sizing: content-box;
  position: sticky;

  &:after,&:before{
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    bottom:0;
  }
  &:before{
    background: url("../images/ep-login-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -2;
  }
  &:after{
    background: radial-gradient(ellipse at center, rgba(242,242,242,0) 0%, rgba(242,242,242,0.5) 100%);
    z-index: -1;
  }
  & > div{
    box-sizing: border-box;
  }

}

.login-card {
  width: 40em;
  padding: 24px;

  &#{&}{
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%), 0 3px 3px rgb(0 0 0 / 10%) !important;
  }
  border-radius: 3px;
  &-brand{
    padding: 24px;
    display: flex;
    justify-content: center;
    &>div{
      flex-grow: 0;
    }

  }
}

.login-card-subtitle {
  text-align: center;
}
</style>
