<template>
  <v-dialog
    v-model="dialog"
    content-class="modal-window-container"
    @input="onModalChange"
  >
    <v-card data-cy="support-ticket-modal-window">
      <v-card-title>
        <span
          :data-cy="`support-ticket-modal-window-title-${ticket.id}`"
          class="headline"
        >{{ ticket.subject }}</span>
        <v-spacer />
        <v-icon
          data-cy="change-plan-modal-close"
          @click="closeModal"
        >
          {{ closeIcon }}
        </v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <material-support-ticket-info />
        <core-element-errors-message
          :show-errors="showApiErrors"
          :errors="errors"
        />
        <v-divider />
        <h3
          v-t="'comments'"
          class="mt-3"
        />
        <material-support-ticket-add-comment />
        <material-support-ticket-comments />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { CLOSE_ICON } from '@/constants/app'
import { ROUTE_SUPPORT_CENTER } from '@/constants/routeNames'

export default {
  data () {
    return {
      dialog: true,
      closeIcon: CLOSE_ICON
    }
  },
  computed: {
    ...mapGetters('support', { ticketGetter: 'ticket' }),
    ...mapState('support', ['errors', 'showApiErrors']),
    ticket () {
      return this.ticketGetter(this.$route.params.clientId, this.$route.params.ticketId)
    }
  },
  methods: {
    ...mapActions('support', ['getDataIfNeed']),
    onModalChange: function (payload) {
      if (!payload) {
        this.closeModal()
      }
    },
    closeModal () {
      this.$router.push({ name: ROUTE_SUPPORT_CENTER })
    }
  }
}
</script>
