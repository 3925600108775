export default function getDefaultState () {
  return {
    clients: {},
    loading: false,
    commentsLoading: false,
    attachmentsLoading: false,
    errors: {},
    showApiErrors: false,
    showClosedTickets: false,
    newTicketSubmitted: false,
    newCallbackRequestSubmitted: false,
    newCallbackRequestFields: null,
    dealRegistrationSubmitted: false
  }
}
