import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0D65F2',
        secondary: '#f2f2f2',
        accent: '#7DC6E6',
        error: '#fe7d99',
        info: '#0D65F2',
        success: '#01c8a9',
        warning: '#fa9b3c'
      }
    }
  },
  colors: {
    shades: {
      black: '#041E49'
    }
  },
  icons: {
    iconfont: 'md'
  }
})
