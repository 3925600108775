import axios from 'axios'
import { handleRequestsErrors } from './requests'

export const downloadFile = async (url, updateDataAction = null) => {
  try {
    const response = await axios.get(url, { responseType: 'arraybuffer' })
    return response.data
  } catch (e) {
    handleRequestsErrors(e, updateDataAction)
    throw new Error('An error occurred during file download')
  }
}

export const uploadFileToBrowser = (data, fileName, fileType) => {
  const blob = new Blob([data], {
    type: `application/${ fileType}`
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `${fileName }.${ fileType}`
  link.click()
}
