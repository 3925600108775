import { Trans } from '../plugins/Translation'
import BaseLayout from '../views/BaseLayout'
import BillingDetails from '../views/BillingDetails'
import InvoicesAndQuotations from '../views/InvoicesAndQuotations'
import Login from '../views/Login'
import Overview from '../views/Overview'
import PaymentCanceled from '../views/PaymentCanceled'
import PaymentSuccess from '../views/PaymentSuccess'
import PaymentsAndMethods from '../views/RecurringPayments'
import ProductDetails from '../views/ProductDetails'
import SupportCenter from '../views/SupportCenter'
import TeamMembers from '../views/TeamMembers'
import TicketModal from '../views/modals/TicketModal'
import ResetPassword from '../views/ResetPassword'
import {
  ROUTE_LOGIN,
  ROUTE_OVERVIEW,
  ROUTE_INVOICES_AND_QUOTATIONS,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_PAYMENT_CANCELED,
  ROUTE_PRODUCT_DETAILS,
  ROUTE_PAYMENT_DETAILS,
  ROUTE_BILLING_DETAILS,
  ROUTE_TEAM_MEMBERS,
  ROUTE_SUPPORT_CENTER,
  ROUTE_TICKET_MODAL,
  ROUTE_RESET_PASSWORD
} from '../constants/routeNames'

export default [
  {
    path: '/:locale',
    component: BaseLayout,
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: 'login',
        name: ROUTE_LOGIN,
        component: Login
      },
      {
        path: 'reset-password',
        name: ROUTE_RESET_PASSWORD,
        component: ResetPassword
      },
      {
        path: ':clientId',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'payment-success',
            name: ROUTE_PAYMENT_SUCCESS,
            component: PaymentSuccess
          },
          {
            path: 'payment-canceled',
            name: ROUTE_PAYMENT_CANCELED,
            component: PaymentCanceled
          },
          {
            path: 'overview',
            name: ROUTE_OVERVIEW,
            component: Overview
          },
          {
            path: 'product-details/:contractId?',
            name: ROUTE_PRODUCT_DETAILS,
            component: ProductDetails
          },
          {
            path: 'invoices-and-quotations',
            name: ROUTE_INVOICES_AND_QUOTATIONS,
            component: InvoicesAndQuotations
          },
          {
            path: 'payment-details',
            name: ROUTE_PAYMENT_DETAILS,
            component: PaymentsAndMethods
          },
          {
            path: 'billing-details',
            name: ROUTE_BILLING_DETAILS,
            component: BillingDetails
          },
          {
            path: 'team-members',
            name: ROUTE_TEAM_MEMBERS,
            component: TeamMembers
          },
          {
            path: 'support-center',
            name: ROUTE_SUPPORT_CENTER,
            component: SupportCenter,
            children: [
              { path: 'tickets/:ticketId', component: TicketModal, name: ROUTE_TICKET_MODAL }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect () {
      return Trans.getUserSupportedLang()
    }
  }
]
