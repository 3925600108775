<template>
  <v-main>
    <core-toolbar
      v-if="!$route.path.match('/login') && !$route.path.match('/reset-password')"
      class="elevation-0"
    />

    <core-products-drawer
      v-if="
        $route.name && $route.name.match(productDetailsRoute)
      "
    />

    <core-drawer
      v-if="!$route.path.match('/login') && !$route.path.match('/reset-password') && !partnerOverviewPage"
    />
    <div
      id="core-view"
      class="pa-4"
    >
      <div style="width: 100%; display: flex">
        <v-fade-transition mode="out-in">
          <router-view style="width: 100%" />
        </v-fade-transition>
      </div>
    </div>
  </v-main>
</template>

<script>
import lodash from 'lodash'
import { ROLE_ADMIN, ROLE_NAME } from '../constants/app'
import { ROUTE_PRODUCT_DETAILS } from '../constants/routeNames'

export default {
  data () {
    return {
      productDetailsRoute: ROUTE_PRODUCT_DETAILS,
      adminRole: ROLE_ADMIN,
      accountRole: localStorage.getItem(ROLE_NAME)
    }
  },
  computed: {
    partnerOverviewPage () {
      return (
        this.accountRole === this.adminRole &&
                this.$route.params.clientId === 'my'
      )
    }
  },
  updated () {
    this.accountRole = localStorage.getItem(ROLE_NAME)
  },
  metaInfo () {
    return {
      title: `${this.$i18n.t(
                `drawer.${this.camelize(this.$route.name)}`
            )} - Client Zone - Easy Software`
    }
  },
  methods: {
    removeError () {
      this.setShowPendingRequestWarning(false)
    },
    camelize (string) {
      return lodash.camelCase(string)
    }
  }
}
</script>
