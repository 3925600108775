<template>
  <v-app>
    <div
      v-if="showPendingRequestWarning"
      class="d-flex justify-center service-unavailable-warning"
    >
      <v-alert
        :value="true"
        type="warning"
        :icon="warningIcon"
        dismissible
        class="core-element-info-message"
        @input="setShowPendingRequestWarning(false)"
      >
        <div class="warning-title">
          {{ $t('pendingRequests.serviceIsTemporaryUnavailable') }}
        </div>
        <div>
          {{ $t('pendingRequests.theRequestHasOccurredButTheDataWillBeUpdatedWhenTheServerIsAvailable') }}
        </div>
        <div>{{ $t('pendingRequests.ifTheDataDidntUpdateTryToSendRequestAgainOrContactYourAccountManager') }}</div>
      </v-alert>
    </div>
    <router-view />
  </v-app>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EXCLAMATION_ICON, ROLE_CLIENT, ROLE_NAME } from '@/constants/app'

export default {
  data () {
    return {
      warningIcon: EXCLAMATION_ICON,
      smartSuppReady: false
    }
  },
  computed: {
    ...mapState('pendingRequests', ['showPendingRequestWarning']),
    ...mapState('client', ['loadingProfileSetting', 'clients']),
    ...mapState('login', ['role']),
    client () {
      return this.clients.my
    },
    smartSuppWatcher () {
      return `${this.client?.fullName}|${this.client?.email}|${this.loadingProfileSetting}|${this.smartSuppReady}`
    },
    isClientView () {
      return this.role === null || this.role === ROLE_CLIENT
    }
  },
  watch: {
    smartSuppWatcher () {
      if (this.client && this.smartSuppReady && this.isClientView) {
        this.setSmartSuppUser()
      }
    },
    role () {
      this.setupChat()
    }
  },
  mounted () {
    this.setRole(localStorage.getItem(ROLE_NAME))

    this.setupSmartSupp()
  },
  methods: {
    ...mapMutations('pendingRequests', ['setShowPendingRequestWarning']),
    ...mapMutations('login', ['setRole']),
    setupChat () {
      if (this.isClientView) {
        this.showSmartSupp()
      } else {
        this.hideSmartSupp()
      }
    },
    setSmartSuppUser () {
      window.smartsupp && window.smartsupp('name', this.client.fullName)
      window.smartsupp && window.smartsupp('email', this.client.email)
    },
    showSmartSupp () {
      window.smartsupp && window.smartsupp('chat:show')
    },
    hideSmartSupp () {
      window.smartsupp && window.smartsupp('chat:hide')
    },
    setupSmartSupp () {
      const component = this
      window._smartsupp = window._smartsupp || {}
      window._smartsupp.key = process.env.VUE_APP_SMART_SUPP_KEY
      window.smartsupp || (function (documentElement) {
        const smartsuppObject = window.smartsupp = function () {
          smartsuppObject._.push(arguments)
        }
        smartsuppObject._ = []
        const firstScriptElement = documentElement.getElementsByTagName('script')[0]

        const scriptElement = documentElement.createElement('script')
        scriptElement.type = 'text/javascript'
        scriptElement.async = true
        scriptElement.src = '//www.smartsuppchat.com/loader.js?'
        scriptElement.onload = function () {
          component.smartSuppReady = true
        }

        firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement)
      })(document)
    }
  }
}
</script>

<style scoped>
.service-unavailable-warning {
  padding-top: 64px;
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
}
.warning-title {
  font-weight: bold;
}
</style>
