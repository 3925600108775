import axios from 'axios'
import {
  SUPPORTED_LANGUAGES,
  DEFAULT_LANGUAGE
} from '../constants/localization'
import i18n from '../plugins/i18n'

const Trans = {
  get defaultLanguage () {
    return DEFAULT_LANGUAGE
  },
  get supportedLanguages () {
    return SUPPORTED_LANGUAGES
  },
  get currentLanguage () {
    return i18n.locale
  },
  set currentLanguage (lang) {
    i18n.locale = lang
  },
  getUserSupportedLang () {
    const userPreferredLang = Trans.getUserLang()
    const userLangCode = userPreferredLang.langNoISO.toLocaleLowerCase()
    if (Trans.isLangSupported(userLangCode)) {
      return userLangCode
    }
    return Trans.defaultLanguage
  },
  getUserLang () {
    const lang = navigator.language
    return {
      lang: lang,
      langNoISO: lang.split('-')[0].toUpperCase()
    }
  },
  setI18nLanguageInServices (lang) {
    Trans.currentLanguage = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
  },
  async changeLanguage (lang) {
    if (!Trans.isLangSupported(lang)) {
      lang = Trans.defaultLanguage
    }
    if (i18n.locale === lang) return lang

    const message = await Trans.loadLanguageFile(lang)
    i18n.setLocaleMessage(lang, message.default || message)
    return Trans.setI18nLanguageInServices(lang)
  },
  async loadLanguageFile (lang) {
    return import(
      /* webpackChunkName: "localization-[request]" */ `../localization/${lang}.json`
    )
  },
  isLangSupported (lang) {
    return Trans.supportedLanguages.includes(lang)
  },
  async routeMiddleware (to, from, next) {
    const lang = to.params.locale
    if (!Trans.isLangSupported(lang)) {
      return next(Trans.getUserSupportedLang())
    }

    await Trans.changeLanguage(lang)
    return next()
  },
  i18nRoute (to) {
    return {
      ...to,
      params: { locale: this.currentLanguage, ...to.params }
    }
  }
}

export { Trans }
