import VueI18n from 'vue-i18n'
import Vue from 'vue'
import {
  LOCALIZATIONS_FOLDER_NAME,
  FALLBACK_LANGUAGE
} from '../constants/localization'

Vue.use(VueI18n)
export default new VueI18n({
  localeDir: LOCALIZATIONS_FOLDER_NAME,
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: {}
})
