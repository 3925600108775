import { INVOICES_PER_PAGE_DEFAULT_COUNT } from '@/constants/app'

export default {
  invoices: state => clientId => {
    return Object.prototype.hasOwnProperty.call(state.clients, clientId) && state.clients[clientId].invoices
      ? state.clients[clientId].invoices
      : []
  },
  quotations: state => clientId => {
    return state.clients[clientId] && state.clients[clientId].quotations
      ? state.clients[clientId].quotations
      : []
  },
  quotationsPendingInvoice: state => clientId => {
    return Object.prototype.hasOwnProperty.call(state.clients, clientId) &&
    state.clients[clientId].quotationsPendingInvoice
      ? state.clients[clientId].quotationsPendingInvoice
      : []
  },
  paidInTotalSum: state => clientId => {
    return state.clients[clientId] && state.clients[clientId].paidInTotalSum
      ? state.clients[clientId].paidInTotalSum
      : ''
  },
  missingPaymentsSum: state => clientId => {
    return state.clients[clientId] && state.clients[clientId].missingPaymentsSum
      ? state.clients[clientId].missingPaymentsSum
      : 0.0
  },
  pagination: state => clientId => {
    return Object.prototype.hasOwnProperty.call(state.clients, clientId) && state.clients[clientId].pagination
      ? state.clients[clientId].pagination
      : {
          page: 1,
          itemsPerPage: INVOICES_PER_PAGE_DEFAULT_COUNT
        }
  },
  bankAccount: state => clientId => {
    return state.clients[clientId] && state.clients[clientId].bankAccount
      ? state.clients[clientId].bankAccount
      : {}
  },
  allInvoicesIds: state => clientId => {
    return Object.prototype.hasOwnProperty.call(state.clients, clientId) && state.clients[clientId].allInvoicesIds
      ? state.clients[clientId].allInvoicesIds
      : []
  }
}
