import { result } from 'lodash'
import i18n from '@/plugins/i18n'
import { PENDING_REQUESTS_CODE } from '@/constants/pendingRequests'

const getApiErrorMessage = (error) => {
  if (result(error, 'response.status') !== PENDING_REQUESTS_CODE) {
    const errorData = result(error, 'response.data')

    return (errorData && (errorData.message ||
    errorData.error ||
    errorData.messages ||
    errorData.errors)) ||
    i18n.t('unknownErrorHasOccurredPleaseTryAgainLater')
  }
}

export default getApiErrorMessage
