<template>
  <v-card class="text-center d-table">
    <v-card-title>
      <span
        class="ma-auto"
        data-cy="payment-canceled"
      >
        {{ $t('payment.paymentWasCanceled') }}
      </span>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <p>
        {{
          $t(
            'payment.yourOrderHasBeenPlacedButAProblemOccurredDuringOnLinePayment'
          )
        }}
      </p>
      <p>
        {{ $t('payment.youWillYourOrderIn') }}
        <router-link
          depressed
          :to="{ name: invoicesAndQuotationsRoute }"
          data-cy="link-quotations"
        >
          {{ $t('payment.theListOfQuotations') }}
        </router-link>
        <br>
        {{ $t('payment.youCanPayItOnLineAgainThere') }}
      </p>
      <p>
        {{ $t('payment.ifYouNeedHelpPleaseContactUsIn') }}
        <router-link
          :to="{ name: supportCenterRoute }"
          data-cy="link-support"
        >
          {{ $t('payment.supportCenter') }}
        </router-link>
        <br>
        {{ $t('payment.thankYouForYourPatience') }}
      </p>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <p>
        {{ $t('payment.youCanAlsoReachYourAccountManagerDirectly') }}
      </p>
      <div
        class="ma-auto"
        style="display: table"
      >
        <core-element-manager-contacts class="text-left" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  ROUTE_INVOICES_AND_QUOTATIONS,
  ROUTE_SUPPORT_CENTER
} from '../constants/routeNames'
export default {
  data () {
    return {
      invoicesAndQuotationsRoute: ROUTE_INVOICES_AND_QUOTATIONS,
      supportCenterRoute: ROUTE_SUPPORT_CENTER
    }
  }
}
</script>
