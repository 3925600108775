import { getClientIdForRequest, requests } from '../../../utils/requests'
import { contractsRequest } from '../../../utils/requestsTemplates'
import fixAPI from './fixAPI'

export default {
  getDataIfNeed: async ({ dispatch, commit, state }, clientId) => {
    if (clientId && !Object.prototype.hasOwnProperty.call(state.clients, clientId)) {
      const clientIdForRequest = getClientIdForRequest(clientId)
      const response = await requests.get(contractsRequest(clientIdForRequest))

      response.data = fixAPI(response.data)

      commit('set', { id: clientId, info: response.data })
    }
  }
}
