import { TYPE_SERVER } from '@/constants/app'

export default function (data) {
  data = data.map(contract => {
    contract.id = contract.guid
    if (contract.product && contract.product.type === TYPE_SERVER) {
      contract.product.extendSubscription = false
    }
    return contract
  })
  return data
}
