<template>
  <div data-cy="overview-container">
    <material-overview-partner-view v-if="isPartnerView" />
    <material-overview-client-view v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ROLE_ADMIN, ROLE_NAME } from '../constants/app'

export default {
  data () {
    return {
      socialIntentsReady: false
    }
  },
  computed: {
    ...mapState('client', ['loadingProfileSetting', 'clients']),
    client () {
      return this.clients.my
    },
    socialIntentsWatcher () {
      return `${this.client?.email}|${this.loadingProfileSetting}|${this.socialIntentsReady}`
    },
    isPartnerView () {
      return localStorage.getItem(ROLE_NAME) === ROLE_ADMIN
    }
  },
  watch: {
    socialIntentsWatcher () {
      if (this.client && this.smartSuppReady && this.isPartnerView) {
        this.setSocialIntentsUser()
      }
    }
  },
  mounted () {
    if (this.isPartnerView) {
      this.setupSocialIntents()
      this.showSocialIntents()
    }
  },
  beforeDestroy () {
    if (this.isPartnerView) {
      this.hideSocialIntents()
    }
  },
  methods: {
    setSocialIntentsUser () {
      window.SI_API && window.SI_API.setChatInfo(this.client.fullName, this.client.email)
    },
    showSocialIntents () {
      window.SI_API && window.SI_API.showTab()
    },
    hideSocialIntents () {
      window.SI_API && window.SI_API.hideTab()
    },
    setupSocialIntents () {
      const component = this
      window.socialintents || (function (documentElement) {
        const socialintentsObject = window.socialintents = function () {
          socialintentsObject._.push(arguments)
        }
        socialintentsObject._ = []
        const firstScriptElement = documentElement.getElementsByTagName('script')[0]

        const scriptElement = documentElement.createElement('script')
        scriptElement.type = 'text/javascript'
        scriptElement.async = true
        scriptElement.src = `//www.socialintents.com/api/socialintents.1.3.js#${process.env.VUE_APP_SOCIAL_INTENTS_KEY}`
        scriptElement.onload = function () {
          component.socialIntentsReady = true
        }

        firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement)
      })(document)
    }
  }
}
</script>
