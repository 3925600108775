import router from '../../../router'

const isProductPlanChanged = (state, rootGetters) => {
  const newPlan = state.formFields
  const productContract = rootGetters['contract/contract']({
    clientId: router.currentRoute.params.clientId,
    contractId: router.currentRoute.params.contractId
  })
  const oldPlan = {
    ...productContract,
    ...productContract.product
  }

  if (
    oldPlan.maxUsersCount !== newPlan.maxUsersCount ||
        oldPlan.subscriptionType !== newPlan.subscriptionType ||
        newPlan.extendSubscription ||
        newPlan.bundleId !== oldPlan.bundleId ||
        !pluginsAreSame(newPlan, oldPlan)
  ) {
    return true
  } return false
}

export default {
  isProductPlanChanged
}

const pluginsAreSame = (newPlan, oldPlan) => {
  let pluginsSameness = true
  if (newPlan.plugins.length !== oldPlan.plugins.length) {
    pluginsSameness = false
  } else {
    newPlan.plugins.forEach(newPlugin => {
      if (
        !oldPlan.plugins.find(
          oldPlugin => oldPlugin.id === newPlugin.id
        )
      ) {
        pluginsSameness = false
      }
    })
  }
  return pluginsSameness
}
