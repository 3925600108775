import { CHANGE_PLAN_MODAL_WINDOW_STATE_CLOSED } from '../../../constants/app'

export default function getDefaultState () {
  return {
    changePlanModalWindowState: CHANGE_PLAN_MODAL_WINDOW_STATE_CLOSED,
    warnings: {},
    loadingOrderSummary: false,
    loadingPlugins: false,
    availablePlugins: null,
    downloadingPackage: null
  }
}
