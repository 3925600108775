import {
  reset,
  set,
  setClientInfo,
  removeFromClientArray,
  appendToClientArray,
  replaceItemInClientArray, setErrors
} from '../../../utils/vuex'
import getDefaultState from './defaultState'
const defaultState = getDefaultState()

export default {
  set: setClientInfo(),
  setLoadingTeamMember: set('loadingTeamMember'),
  setErrors: setErrors(),
  removeTeamMember: removeFromClientArray('members'),
  appendTeamMember: appendToClientArray('members'),
  replaceTeamMember: replaceItemInClientArray('members'),
  reset: reset(defaultState)
}
