const DEFAULT_LANGUAGE = 'en'
const FALLBACK_LANGUAGE = 'en'
const LOCALIZATIONS_FOLDER_NAME = 'localization'
const SUPPORTED_LANGUAGES = ['en', 'cs']

module.exports = {
  DEFAULT_LANGUAGE,
  FALLBACK_LANGUAGE,
  SUPPORTED_LANGUAGES,
  LOCALIZATIONS_FOLDER_NAME
}
