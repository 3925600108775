<template>
  <material-team-members-table
    :headers="headers"
    :members="members"
    :limit="limit"
  />
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('member', ['clients', 'limit']),
    members () {
      return Object.prototype.hasOwnProperty.call(
        this.clients,
        this.$route.params.clientId
      )
        ? this.clients[this.$route.params.clientId].members
        : []
    },
    headers () {
      return [
        {
          text: this.$i18n.t('team.name'),
          value: 'name'
        },
        {
          text: this.$i18n.t('team.accessLevel'),
          value: 'permissions'
        },
        {
          text: this.$i18n.t('team.emailAddress'),
          value: 'email'
        },
        {
          text: this.$i18n.t('actions'),
          value: 'actions'
        }
      ]
    }
  }
}
</script>
