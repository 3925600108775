const getTicket = (state, clientId, ticketId) => {
  if (Object.prototype.hasOwnProperty.call(state.clients, clientId) &&
    state.clients[clientId].openTickets &&
    state.clients[clientId].closedTickets
  ) {
    const allTickets = [...state.clients[clientId].openTickets, ...state.clients[clientId].closedTickets]
    return allTickets.find(ticket => ticket.id === parseInt(ticketId))
  }
  return null
}

export default {
  closedTickets: state => clientId => {
    if (
      Object.prototype.hasOwnProperty.call(state.clients, clientId) &&
      state.clients[clientId].closedTickets &&
      state.clients[clientId].closedTickets.length
    ) {
      return state.clients[clientId].closedTickets
    }
    return []
  },
  openTickets: state => clientId => {
    return Object.prototype.hasOwnProperty.call(state.clients, clientId) && state.clients[clientId].openTickets
      ? state.clients[clientId].openTickets
      : []
  },
  lastClosedTicketDate: state => clientId => {
    if (
      Object.prototype.hasOwnProperty.call(state.clients, clientId) &&
      state.clients[clientId].closedTickets &&
      state.clients[clientId].closedTickets.length
    ) {
      const count = state.clients[clientId].closedTickets.length
      return state.clients[clientId].closedTickets[count - 1].lastUpdate
    }
    return null
  },
  ticket: state => (clientId, ticketId) => {
    return getTicket(state, clientId, ticketId)
  },
  ticketDescription: state => (clientId, ticketId) => {
    const ticket = getTicket(state, clientId, ticketId)
    return ticket ? ticket.description : ''
  },
  links: state => clientId => {
    if (Object.prototype.hasOwnProperty.call(state.clients, clientId)) {
      return state.clients[clientId].links
    }
    return {}
  },
  comments: state => (clientId, ticketId) => {
    if (Object.prototype.hasOwnProperty.call(state.clients, clientId)) {
      return state.clients[clientId].comments && state.clients[clientId].comments[ticketId]
        ? state.clients[clientId].comments[ticketId]
        : []
    }
  },
  getNumberOfRequestsById: state => clientId => (
    Object.prototype.hasOwnProperty.call(state.clients, clientId) &&
    state.clients[clientId].requests
      ? state.clients[clientId].requests.length
      : 0
  )
}
